import {create} from 'zustand';

const useSnackbarStore = create<SnackbarStoreState>((set) => ({
    isOpen: false,
    message: '',
    severity: 'info', 
    openSnackbar: (message: string, severity: 'success' | 'info' | 'warning' | 'error') => set({ isOpen: true, message, severity }),
    closeSnackbar: () => set({ isOpen: false }),
  }));

export default useSnackbarStore;