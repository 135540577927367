import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AccountBanner from '../../../components/AccountBanner';
import LoginNavigation from '../../../components/LoginNavigation';
import { DOWLOAD_APP_LINK_APPLE, DOWNLOAD_APP_LINK } from '../../../domains/DownloadAppLink';
import './CreateAccount.scss';

function CreateAccount() {
  const { t } = useTranslation();

  return (
    <Box className="ilpf-create-account-screen">
      <Box className="ilpf-create-account-content">
        <LoginNavigation />
        <Box className="ilpf-create-account-main-box">
          <Box className="ilpf-create-account-title">
            <h1>{t('create_account_title')}</h1>
          </Box>
          <Box className="ilpf-create-account-text">
            <span>{t('create_account_text')}</span>
          </Box>
          <Box className="ilpf-create-account-button">
            <Button
              href={DOWNLOAD_APP_LINK}
              className="playstore-button"
              variant="outlined"
            >
              <img
                src={`${process.env.PUBLIC_URL}/resources/images/google-play.svg`}
                alt="Google Playstore"
                title="Google Playstore"
              />
              <span>{t('create_account_store')}</span>
            </Button>
          </Box>
          <Box className="ilpf-create-account-button">
            <Button
              href={DOWLOAD_APP_LINK_APPLE}
              className="apple-button"
              variant="outlined"
            >
              <img
                src={`${process.env.PUBLIC_URL}/resources/images/apple.svg`}
                alt="Apple Store"
                title="Apple Store"
              />
              <span>{t('create_account_store_apple')}</span>
            </Button>
          </Box>
        </Box>
      </Box>
      <AccountBanner screen={true} />
    </Box>
  );
}

export default CreateAccount;
