import InboxOutlined from '@mui/icons-material/InboxOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, IconButton, InputBase, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Error } from '../../../../components/Error';
import Loading from '../../../../components/Loading';
import './SideCarMenu.scss';
import { Farm } from '../../../../domains/Farm';
import { useFarmStore } from '../../../../store/FarmStore/farmStore';

export function SideCarMenu() {
  const { t } = useTranslation();
  const {
    farms,
    setSelectedFarmDisplayId,
    selectedFarmDisplayId,
    farmsIsLoading,
    farmsIsError,
  } = useFarmStore();
  const [displayFarmList, setDisplayFarmList] = useState<Farm[]>([]);

  const handleFarmSearch = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target?.value?.length >= 3) {
      const filteredFarmList = farms.filter(
        (farm) =>
          farm.name
            .toUpperCase()
            .includes(event.target.value.toUpperCase()) ||
            farm.car_id?.toUpperCase().includes(event.target.value.toUpperCase()),
      );
      setDisplayFarmList(filteredFarmList);
    } else {
      setDisplayFarmList(farms);
    }
  };

  useEffect(() => {
    setDisplayFarmList(farms);
  }, [farms]);

  if (farmsIsLoading) {
    return <LoginSection />;
  }

  if (farmsIsError) {
    return <ErrorSection />;
  }

  return (
    <Box component="aside" className="ilpf-map-private-side-box">
      <Box className="ilpf-map-private-car-search-bar">
        <InputBase
          className="car-search-bar-input"
          placeholder={t('car_search_input_placeholder')}
          inputProps={{ 'aria-label': t('car_search_input_aria_label') }}
          onChange={handleFarmSearch}
        />
        <IconButton
          className="car-search-bar-icon"
          aria-label="search-car-name-number"
        >
          <SearchIcon />
        </IconButton>
      </Box>

      <ul className="ilpf-map-private-cars-list">
        {displayFarmList.length > 0 &&
          displayFarmList.map((farm) => (
            <li key={farm.car_id}>
              <Button
                onClick={() => setSelectedFarmDisplayId(farm.car_id)}
                className={`car-list-item ${selectedFarmDisplayId === farm.car_id ? 'selected' : ''}`}
              >
                <Typography className="car-list-item-farm-name">
                  {farm.name}
                </Typography>
                <Typography className="car-list-item-id">
                  {farm.car_id || t('empty_car')}
                </Typography>
              </Button>
            </li>
          ))}
        {displayFarmList.length === 0 && (
          <Box className="car-list-empty">
            <InboxOutlined sx={{ fontSize: 70, color: '#808080' }} />
            <Typography className="car-list-empty-title">
              {t('empty_car_list_title')}
            </Typography>
            <Typography className="car-list-empty-description">
              {t('empty_car_list_description')}
            </Typography>
          </Box>
        )}
      </ul>
    </Box>
  );
}

function LoginSection() {
  return (
    <Box component="aside" className="ilpf-map-private-side-box">
      <Loading position="static" />
    </Box>
  );
}

function ErrorSection() {
  return (
    <Box component="aside" className="ilpf-map-private-side-box">
      <Error position="static" errorMessageKey="error_no_car_registred" />
    </Box>
  );
}
