import { create } from 'zustand';
import { GlebeState, GlebeStore } from './GlebeStore.types';

const glebeStoreInitialState: GlebeState = {
  glebeIsLoading: false,
  glebeIsError: false,
  glebeList: [],
  selectedGlebe: null,
};

export const useGlebeStore = create<GlebeStore>((set) => ({
  ...glebeStoreInitialState,
  reset: () => set(glebeStoreInitialState),
  setGlebeIsLoading: () =>
    set((state) => ({ ...state, glebeIsLoading: true, glebeIsError: false })),
  loadGlebeData: (glebeList) =>
    set((state) => ({
      ...state,
      glebeIsLoading: false,
      glebeIsError: false,
      glebeList,
    })),
  setGlebeIsError: () =>
    set((state) => ({ ...state, glebeIsLoading: false, glebeIsError: true })),
  setSelectedGlebe: (selectedGlebe) =>
    set((state) => ({ ...state, selectedGlebe })),
}));
