/* eslint-disable @typescript-eslint/no-explicit-any */
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import './CustomTooltip.scss';
interface CustomTooltipProps extends TooltipProps<number, string> {
  getAdditionalInfoKey: (key: string) => Array<string>;
  unavailableDataMessage?: string;
  parentId: string;
}
export function CustomTooltip({
  active,
  payload,
  label,
  getAdditionalInfoKey,
  unavailableDataMessage,
  parentId,
}: CustomTooltipProps) {
  const displayUnavailableMessage =
    unavailableDataMessage && payload && payload.length === 0 && parentId;
  const displayDefaultTooltip = active && payload && payload.length;
  if (displayUnavailableMessage) {
    return (
      <UnavailableMessage
        active={active}
        parentId={parentId}
        unavailableDataMessage={unavailableDataMessage}
      />
    );
  }

  if (displayDefaultTooltip) {
    return (
      <DefaultTooltip
        payload={payload}
        label={label}
        getAdditionalInfoKey={getAdditionalInfoKey}
      />
    );
  }

  return null;
}

function getValueFromProtertyPath(object: object, propertyPath: string) {
  const parts = propertyPath.split('.');
  let value: any | undefined = object;

  parts.forEach((part) => {
    if (value && typeof value === 'object' && part in value) {
      value = value[part as keyof typeof object];
    } else {
      value = undefined;
    }
  });

  return value;
}

function UnavailableMessage({
  parentId,
  active,
  unavailableDataMessage,
}: {
  parentId: string;
  active: boolean | undefined;
  unavailableDataMessage: string;
}) {
  const parentElement = document.getElementById(parentId);
  const tooltipWrapper = parentElement?.querySelector(
    '.recharts-tooltip-wrapper',
  );

  if (!tooltipWrapper) {
    return null;
  }

  const wrapperContainsVisibleClass = tooltipWrapper.classList.contains(
    'recharts-custom-tooltip-wrapper-visible',
  );

  if (active) {
    if (!wrapperContainsVisibleClass) {
      tooltipWrapper.classList.add('recharts-custom-tooltip-wrapper-visible');
    }

    return (
      <div className="recharts-custom-tooltip">
        <p className="recharts-tooltip-label">{`${unavailableDataMessage}`}</p>
      </div>
    );
  }

  if (wrapperContainsVisibleClass) {
    tooltipWrapper.classList.remove('recharts-custom-tooltip-wrapper-visible');
  }

  return null;
}

function DefaultTooltip({
  label,
  payload,
  getAdditionalInfoKey,
}: {
  label: any;
  payload: Payload<number, string>[];
  getAdditionalInfoKey: (key: string) => Array<string>;
}) {
  return (
    <div className="recharts-custom-tooltip">
      <p className="recharts-tooltip-label">{`${label}`}</p>
      <ul className="recharts-tooltip-item-list">
        {payload.map((dataSerie) => {
          const additionalInfoKeyList =
            getAdditionalInfoKey &&
            dataSerie.dataKey &&
            getAdditionalInfoKey(dataSerie.dataKey as string);
          const additionalInfoList =
            additionalInfoKeyList &&
            additionalInfoKeyList.map((additionalInfoKey) =>
              getValueFromProtertyPath(dataSerie.payload, additionalInfoKey),
            );

          return (
            <li
              className="recharts-tooltip-item"
              style={{ color: dataSerie.color }}
              key={dataSerie.dataKey}
            >
              <p>{`${dataSerie.name} : ${dataSerie.value}`}</p>
              {additionalInfoList &&
                additionalInfoList.map((additionalInfo) => (
                  <p>{additionalInfo}</p>
                ))}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
