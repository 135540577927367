import { Glebe } from '../../domains/Glebe';
import ApiHelper from '../../helpers/ApiHelper';
import { BASE_URLS, URLS } from '../apiConstants';

const GlebeService = {
  getGlebeByCar: (carId: string) =>
    ApiHelper.callApi<Array<Glebe>>({
      baseUrl: BASE_URLS.WEB_API,
      headers: {},
      method: 'GET',
      url: URLS.GET_GLEBE_LIST,
      paramSearch: { car_id: carId },
    }),
};

export default GlebeService;
