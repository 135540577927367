import { useEffect } from 'react';
import GlebeEventService from '../../services/GlebeEventService';
import { useGlebeStore } from '../GlebeStore/glebeStore';
import { glebeEventsConverter } from './glebeEventsConverter';
import { useGlebeEventsStore } from './glebeEventsStore';

export function useLoadGlebeEventsAction() {
  const { selectedGlebe } = useGlebeStore();

  useEffect(() => {
    if (selectedGlebe?.id) {
      loadGlebeEvents({ glebeId: selectedGlebe?.id });
    }
  }, [selectedGlebe?.id]);
}

export function loadAllGlebeEvents() {
  const { selectedGlebe } = useGlebeStore.getState();

  const glebeId = selectedGlebe?.id;

  if (glebeId) {
    loadGlebeEvents({
      glebeId,
      itemsPerPage: '100',
      allGlebeEventsIsActive: true,
    });
  }
}

export function loadLimitedGlebeEvents() {
  const { selectedGlebe } = useGlebeStore.getState();

  const glebeId = selectedGlebe?.id;

  if (glebeId) {
    loadGlebeEvents({
      glebeId,
      itemsPerPage: '5',
      allGlebeEventsIsActive: false,
    });
  }
}

interface LoadGlebeEvents {
  glebeId: string;
  itemsPerPage?: string;
  allGlebeEventsIsActive?: boolean;
}

async function loadGlebeEvents({
  glebeId,
  itemsPerPage = '5',
  allGlebeEventsIsActive = false,
}: LoadGlebeEvents) {
  const {
    setGlebeEventsIsLoading,
    setGlebeEventsIsError,
    loadGlebeEventsData,
  } = useGlebeEventsStore.getState();

  try {
    setGlebeEventsIsLoading();

    const response = await GlebeEventService.getGlebeEventsByGlebeId({
      glebeId,
      page: '1',
      itemsPerPage,
      order: 'DESC',
    });

    const { fertilizerKeysData, glebeEventsList } = glebeEventsConverter(
      response.itens,
    );
    loadGlebeEventsData({
      fertilizerKeysData,
      glebeEventsList,
      allGlebeEventsIsActive,
    });
  } catch (error) {
    setGlebeEventsIsError();
  }
}
