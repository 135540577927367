import { create } from 'zustand';
import { LoadManagableUserDataParams, ManagableUserState, ManagableUserStore } from './managableUserStore.types';


const managableUsersStoreInitialState: ManagableUserState = {
  managableUsersList: [],
  managableUsersIsLoading: false,
  managableUsersIsError: false,
  currentPage: 0,
  itemsPerPage: 10,
  totalOfItems: 0,
  searchTerms: undefined,
};

export const useManagableUserStore = create<ManagableUserStore>((set) => ({
  ...managableUsersStoreInitialState,
  reset: () => set(managableUsersStoreInitialState),
  setManagableUsersIsLoading: () =>
    set((state: ManagableUserState) => ({
      ...state,
      managableUsersIsLoading: true,
      managableUsersIsError: false,
    })),
  loadManagableUsersData: (changedProperties: LoadManagableUserDataParams) =>
    set((state: ManagableUserState) => ({
      ...state,
      managableUsersIsLoading: false,
      managableUsersIsError: false,
      ...changedProperties,
    })),
  setManagableUsersIsError: () =>
    set((state: ManagableUserState) => ({
      ...state,
      managableUsersIsLoading: false,
      managableUsersIsError: true,
    }))
}));
