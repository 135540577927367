/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import GeoMap from '../../components/GeoMap';
import { Box, Grid, Link } from '@mui/material';
import './MapPublic.scss';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../domains/AppRoutes';
import { useTranslation } from 'react-i18next';
import DataTable from './component/DataTable';
import DynamicCombo from './component/Form';

 function GeoMapWrapper () {
  const [calculateData, setCalculateData] = useState<any>(0);
  const [geoJSON, setGeoJSON] = useState<any>();

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className='container'>
      <Grid container spacing={1}>
        <Grid item sm={1.1} className='grid'>
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/rede-ilpf-logo.svg`}
            alt="ILPF Logo"
            title="ILPF Logo"
          />
        </Grid>
        <Grid item sm={1.5}></Grid>
        <Grid item sm={6.1}>
          <DynamicCombo onCalculateDataChange={setCalculateData} onGeoJSON={setGeoJSON}/>
        </Grid>
        <Grid item sm={2} className='ilpf-login-account'>
          <Box>
            <Link onClick={() => navigate(PublicRoutes.LOGIN)} className='link-access'>
              {t('login_link_access')}
            </Link>
          </Box>
        </Grid>
        <Grid item sm={1.3} className='last-grid-column'>
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/ilpfdigital-logo.png`}
            alt="ILPF Digital Logo"
            title="ILPF Digital Logo"
            className='logo-digital'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ height: 'calc(100vh - 64px)' }}>
        <Grid item xs={9} className='geo-map'>
          <GeoMap
            geoJsonData={geoJSON}
          />
        </Grid>
        <Grid item xs={3}>
          <DataTable data={calculateData}/>
        </Grid>
      </Grid>
    </div>
  );
}
export default GeoMapWrapper;