import { NavLink } from 'react-router-dom';
import { DEFAULT_LOGGED_ROUTE } from '../../domains/AppRoutes';
import './SideBar.scss';
import TabComponent from './components/TabComponent';
import { UserMenu } from './components/UserMenu';

function SideBar() {  
  return (
    <nav className="ilpf-side-bar">
      <NavLink className="ilpf-rede-logo" to={DEFAULT_LOGGED_ROUTE}>
        <h1>
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/rede-ilpf-logo.svg`}
            alt="ILPF Logo"
            title="ILPF Logo"
          />
        </h1>
      </NavLink>
      <div className="side-bar-buttons-container">
        <TabComponent />
        <UserMenu />        
      </div>
    </nav>
  );
}

export default SideBar;
