import { IconProps } from './Icon.types';

export function ForestIcon({
  width = '32',
  height = '32',
  color = 'rgb(0,0,0)',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 500 500"
      style={{ width: '100%', height: 'auto' }}
    >
      <g
        transform="matrix(1 0 0 1 254.795811074 220.590319812)"
        id="3Xg4EgvaDmZgfl2M0abv1"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -30.06657 -82.50368 C -21.492559999999997 -97.84664000000001 11.900939999999999 -110.93328 31.756529999999998 -82.50368 C 52.96592 -94.68780000000001 87.26195 -68.51451 81.39552 -46.4026 C 108.47133000000001 -36.4748 121.55797000000001 9.102820000000001 94.48216000000001 20.83567 C 107.56880000000001 64.60822999999999 85.90815 85.36636000000001 47.55075000000001 82.65877 C 22.73126000000001 102.96563 -14.72361999999999 106.57574 -48.117119999999986 82.65877 C -84.66946999999999 95.29415 -112.64780999999999 47.460210000000004 -94.59725999999998 20.835670000000007 C -127.08822999999998 -4.435089999999992 -98.20736999999998 -48.65890999999999 -82.41313999999997 -46.40259999999999 C -80.15681999999997 -80.24736999999999 -47.66584999999997 -91.07768999999999 -30.06656999999997 -82.50368 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 217.6506131182 190.0934169531)"
        id="gUNovG0u-yxvrwXT9NTit"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 37.1452 -24.4797 C 23.607290000000003 -27.638540000000003 5.273580000000003 -31.24865 -1.4953799999999973 -0.562730000000002 C -31.73004 -7.331680000000002 -36.69394 18.39034 -37.145199999999996 26.51308"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1.0285149396 243.6823104693 346.6939104264)"
        id="cQqZRnBjnAhuBOws3eyQZ"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 0 -24.81949 L 0 24.8195"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1.0285149396 266.3176895307 346.6939108534)"
        id="lDrS5Oyu9odHzwt1nzgB5"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 0 -24.81949 L 0 24.8195"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 255.4156299688 372.7924196029)"
        id="qNDvr8WMFO_tEMd3jxU-W"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -43.32177 0 L 43.32176 0"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}
