import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  loadAllGlebeEvents,
  loadLimitedGlebeEvents,
} from '../../../../store/GlebeEventsStore/glebeEventsActions';
import { useGlebeEventsStore } from '../../../../store/GlebeEventsStore/glebeEventsStore';

export function LoadAllGlebeEvents() {
  const { t: translate } = useTranslation();
  const { allGlebeEventsIsActive } = useGlebeEventsStore();

  const buttonLabel = allGlebeEventsIsActive
    ? translate('load_5_glebe_events')
    : translate('load_all_glebe_events');

  const handleOnClick = allGlebeEventsIsActive
    ? loadLimitedGlebeEvents
    : loadAllGlebeEvents;

  return (
    <Button
      type="submit"
      className="load-all-glebe-btn"
      variant="outlined"
      onClick={handleOnClick}
    >
      {buttonLabel}
    </Button>
  );
}
