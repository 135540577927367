import convert from 'color-convert';

export function generateSeriesColor(
  serieIndex: number,
  totalSeries: number,
  isRelatedSerie: boolean,
) {
  const baseMatrixH = (serieIndex / totalSeries) * 360;

  const baseMatrixS = isRelatedSerie ? 40 : 100;

  const colorHex = convert.hsv.hex([baseMatrixH, baseMatrixS, 60]);

  return colorHex;
}
