import {
  FertilizerData,
  Forest,
  GlebeEvent,
  Livestock,
  RawGlebeEvent,
  Tillage,
} from '../../domains/GlebeEvent';
import { FertilizerKeyData } from './glebeEventsStore.types';

export function glebeEventsConverter(items: Array<RawGlebeEvent>): {
  fertilizerKeysData: Record<string, FertilizerKeyData>;
  glebeEventsList: GlebeEvent[];
} {
  const fertilizerKeysData: Record<string, FertilizerKeyData> = {};

  orderItemsByCropYear(items);

  const glebeEventsList: Array<GlebeEvent> = items.map((item) => ({
    name: getGlebeEventName(item),
    cattleHeads: getCattleHeadsData(item.livestock),
    fertilizer: buildFertilizerData(item.tillage, fertilizerKeysData),
    productivity: getTillageProductivity(item.tillage),
    forest: getForestData(item.forest),
  }));

  return { fertilizerKeysData, glebeEventsList };
}

function orderItemsByCropYear(items: Array<RawGlebeEvent>) {
  items.sort((a, b) => {
    if (a.cropYear > b.cropYear) {
      return 1;
    }

    if (b.cropYear > a.cropYear) {
      return -1;
    }

    return 0;
  });
}

function getGlebeInfoByType(item: RawGlebeEvent) {
  const crops = [
    item.multipleCultivations?.mcCrop,
    item.multipleCultivations?.mcSecondCrop,
    item.multipleCultivations?.mcThirdCrop
  ].filter(Boolean).join(' / ');

   switch (item.withoutIntegratedSystem) {
     case 'Culturas Agrícolas':
       return crops
      break;
     case 'Culturas de Cobertura':
       return item.landCovers?.crop;
      break;
     case 'Pastagem solteira':
       return item.singlePastures?.fodder;
      break;
   }
}

function getGlebeEventName(item: RawGlebeEvent) {
  const isIntegratedSystem = !!item.integratedSystem;

  const baseName = `${item.cropYear} *** ${item.productionSystem}`;

  if (!isIntegratedSystem) {
    if (item.withoutIntegratedSystem) {
      return `${item.cropYear} *** ${item.withoutIntegratedSystem} (${getGlebeInfoByType(item)})`;
    }

    return baseName;
  }

  if (item.integratedSystem) {
    const integratedSystemDescription =
      item.integratedSystem.match(/\((.*[^)]?)\)/g);

    return `${baseName} ${integratedSystemDescription}`;
  }

  return baseName;
}

function getCattleHeadsData(livestock: Livestock | null) {
  const livestockUnit = livestock?.livestockUnit
    ? parseInt(livestock?.livestockUnit)
    : undefined;

  const fooder = livestock?.fodder;
  const animalType = livestock?.fodderAnimalType;

  return {
    livestockUnit,
    fooder,
    animalType,
  };
}

const getFertilizerKey = (name: string) =>
  name.toLowerCase().replaceAll(' ', '_');

function populateFertilizerKeysData(
  fertilizerKeysData: Record<string, FertilizerKeyData>,
  fertilizerKey: string,
  fertilizerName: string,
  cropKey: string,
) {
  const currentCropKeysAvailable =
    fertilizerKeysData[fertilizerKey]?.cropKeysAvailable;
  const keyAlreadyMapped =
    currentCropKeysAvailable && currentCropKeysAvailable?.includes(cropKey);

  if (keyAlreadyMapped) {
    return null;
  }

  // eslint-disable-next-line no-param-reassign
  fertilizerKeysData[fertilizerKey] = {
    ...fertilizerKeysData[fertilizerKey],
    key: fertilizerKey,
    name: fertilizerName,
    cropKeysAvailable: currentCropKeysAvailable
      ? [...currentCropKeysAvailable, cropKey]
      : [cropKey],
  };

  return null;
}

function buildFertilizerData(
  tillage: Tillage | null,
  fertilizerKeysData: Record<string, FertilizerKeyData>,
): Record<string, FertilizerData> | undefined {
  const builtFertilizerDataList: Record<string, FertilizerData> | undefined =
    tillage?.cropFertilizers?.reduce(
      (accum, fertilizer) => {
        const fertilizerKey = getFertilizerKey(fertilizer.fertilizerType);

        const originalDataCropKey = fertilizer.firstCrop
          ? 'crop'
          : 'secondCrop';
        const builtDataCropKey = fertilizer.firstCrop
          ? 'firstCrop'
          : 'secondCrop';

        populateFertilizerKeysData(
          fertilizerKeysData,
          fertilizerKey,
          fertilizer.fertilizerType,
          builtDataCropKey,
        );

        const fertilizerData = {
          ...accum[fertilizerKey as keyof typeof accum],
          [builtDataCropKey]: {
            value: parseInt(fertilizer.cropDose),
            crop: tillage[originalDataCropKey],
          },
        };

        return { ...accum, [fertilizerKey]: fertilizerData } as Record<
          string,
          FertilizerData
        >;
      },
      {} as Record<string, FertilizerData>,
    );

  return builtFertilizerDataList;
}

function getTillageProductivity(tillage: Tillage | null) {
  const firstCropProductivity = tillage?.cropProductivity
    ? parseInt(tillage?.cropProductivity)
    : undefined;
  const firstCrop = tillage?.crop;

  const secondCropProductivity = tillage?.secondCropProductivity
    ? parseInt(tillage?.secondCropProductivity)
    : undefined;
  const secondCrop = tillage?.secondCrop;

  return {
    firstCrop: { value: firstCropProductivity, crop: firstCrop },
    secondCrop: { value: secondCropProductivity, crop: secondCrop },
  };
}

function getForestData(forest: Forest | null) {
  const hasForest = forest?.newPlanting === 'Sim' ? 1 : 0;
  const forestry = forest?.forestry;
  const treePurpose = forest?.treePurpose;

  let additionalInfo;
  if (forestry) {
    additionalInfo = forestry;
    if (treePurpose) {
      additionalInfo += ` (${treePurpose})`;
    }
  }

  return {
    hasForest,
    additionalInfo,
  };
}
