import { Alert, Box, Button, Snackbar, TextField } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AccountBanner from '../../../../components/AccountBanner';
import LoginNavigation from '../../../../components/LoginNavigation';
import AccountService from '../../../../services/AccountService';
import '../ForgotPassword.scss';
import { CodeViewProps, CurrentComponent } from '../ForgotPassword.types';

interface CodeFormValues {
  code: string;
}

function CodeView(props: CodeViewProps) {
  const { t } = useTranslation();
  const service = AccountService;
  const [codeViewError, setCodeViewError] = useState<null | string>(null);
  const [open, setOpen] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CodeFormValues>();

  function onSubmit(data: CodeFormValues) {
    const { email, nextComponent } = props;
    const { code } = data;
    service
      .passResetGenerateToken({ code, email })
      .then((response) => {
        nextComponent(CurrentComponent.NEW_PASS, email, response.resetToken);
      })
      .catch((error) => {
        setOpen(true);
        if (error.data && error.data.message) {
          setCodeViewError(error.data.message);
        } else {
          setCodeViewError('Ocorreu um erro');
        }
      });
  }

  const handleClose = () => {
    setOpen(false);
    setCodeViewError('');
  };

  return (
    <Box className="ilpf-forgot-password-screen">
      <Box className="ilpf-forgot-password-content">
        <LoginNavigation />
        <Box className="ilpf-forgot-password-main-box">
          <Box className="ilpf-forgot-password-title">
            <h1>{t('code_view_title')}</h1>
          </Box>
          <Box className="ilpf-forgot-password-text">
            <span>{t('code_view_text')}</span>
          </Box>
          <Box className="ilpf-forgot-password-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="code">{t('code_view_code')}</label>
              <TextField
                id="code"
                type="password"
                {...register('code', { required: true, pattern: /\b\d{6}\b/ })}
                error={!!errors.code}
                variant="outlined"
              />
              {errors.code && errors.code.type === 'required' && (
                <span className="error-message">
                  {t('error_required_code')}
                </span>
              )}
              {errors.code && errors.code.type === 'pattern' && (
                <span className="error-message">{t('error_invalid_code')}</span>
              )}
              <Button
                type="submit"
                className="forgot-password-button"
                variant="contained"
              >
                {t('code_view_button')}
              </Button>
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert severity="error" onClose={handleClose}>
                  {codeViewError}
                </Alert>
              </Snackbar>
            </form>
          </Box>
        </Box>
      </Box>
      <AccountBanner />
    </Box>
  );
}

export default CodeView;
