import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { PrivateRoutes, PublicRoutes } from '../domains/AppRoutes';
import { getIsUserAuthenticated, getStoredUserData } from './UserHelpers';

export type CallApiDataBody = Record<string, string | object>;
interface CallApiData {
  baseUrl: string;
  headers?: object;
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
  url: string;
  paramSearch?: Record<string, string | undefined>;
  data?: CallApiDataBody;
}

async function callApi<ResponseType = unknown>(
  data: CallApiData,
): Promise<ResponseType> {
  let { url } = data;

  if (data.paramSearch !== undefined && data.paramSearch !== null) {
    const mountUrl = new URL(data.baseUrl + data.url);

    Object.keys(data.paramSearch).forEach((property) => {
      const { searchParams } = mountUrl;

      if (data.paramSearch && !!data.paramSearch[property]) {
        searchParams.set(property, data.paramSearch[property] as string);
      }
    });
    mountUrl.search = mountUrl.searchParams.toString();

    url = mountUrl.toString().replace(data.baseUrl, '');
  }

  const api: AxiosInstance = axios.create({
    baseURL: data.baseUrl,
    headers: getRequestHeaders(data),
  });

  api.interceptors.request.use(interceptRequestOnInvalidToken);

  return api({
    method: data.method,
    url,
    data: data.data,
  })
    .then((response) => response.data)
    .catch((error: AxiosError<Error>) => {
      throw error.response;
    });
}

function getRequestHeaders(data: CallApiData) {
  const isUserAuthenticated = getIsUserAuthenticated();
  const authenticationToken = getStoredUserData()?.accessToken;

  const headers = isUserAuthenticated
    ? { ...data.headers, Authorization: `Bearer ${authenticationToken}` }
    : data.headers;

  return headers;
}

function interceptRequestOnInvalidToken(config: InternalAxiosRequestConfig) {
  const isRoutePrivate = Object.values(PrivateRoutes).includes(
    window.location.pathname,
  );
  const isUserAuthenticated = getIsUserAuthenticated();

  if (isRoutePrivate && !isUserAuthenticated) {
    window.location.pathname = PublicRoutes.LOGIN;
  }
  return config;
}

export function isAxiosError(error: unknown): error is AxiosResponse<Error> {
  return !!(error as AxiosResponse<Error>).data;
}

const ApiHelper = {
  callApi,
};

export default ApiHelper;
