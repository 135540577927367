/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import axios from 'axios';
import './Form.scss';
import Loading from '../../../../components/Loading';
import { BASE_URLS } from '../../../../services/apiConstants';
import useSnackbarStore from '../../../../store/Notification/NotificationStore';
import CryptoJS from 'crypto-js';

const URL_IBGE = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados';

const password = process.env.REACT_APP_API_KEY_PASSWORD || '';
const key = process.env.REACT_APP_API_KEY_SECRET || '';
const encryptedApiKey = CryptoJS.AES.encrypt(password, key).toString();

const headers = {
  headers: {
    'x-api-key': `${encryptedApiKey}`
  }
}

interface Props {
    onCalculateDataChange: any;
    onGeoJSON: any;
  }

const DynamicCombo = ({ onCalculateDataChange, onGeoJSON } : Props) => {
  const snackbarStore = useSnackbarStore();
  const [tipo, setTipo] = useState('');
  const [biomas] = useState([
    { nome: 'Amazônia', cd_bioma: 1 },
    { nome: 'Caatinga', cd_bioma: 2 },
    { nome: 'Cerrado', cd_bioma: 3 },
    { nome: 'Mata Atlântica', cd_bioma: 4 },
    { nome: 'Pampa', cd_bioma: 5 },
    { nome: 'Pantanal', cd_bioma: 6 },
  ]);
  const [selectedBioma, setSelectedBioma] = useState('');

  const [ufs, setUfs] = useState([]);
  const [regioes] = useState(['Norte', 'Nordeste', 'Centro-oeste', 'Sudeste', 'Sul']);
  const [selectedRegiao, setSelectedRegiao] = useState('');

  const [selectedUf, setSelectedUf] = useState('');
  const [cidades, setCidades] = useState([]);
  const [selectedCidade, setSelectedCidade] = useState('');
  const [anos, setAnos] = useState([]);
  const [selectedAno, setSelectedAno] = useState('');
  const [, setCalculo] = useState('');

  const [mostrarSelectAno, setMostrarSelectAno] = useState(false);
  const [enable, setEnable] = useState(false);
  const [loading, setLoading] = useState(false); 

  useEffect(() => {  
      axios.get(BASE_URLS.WEB_API_PUBLIC + 'version', headers)
        .then(response => console.log(`API Pública versão: ${response?.data?.version}`))
        .catch(error => {
          if(error?.message == 'Network Error'){
            snackbarStore.openSnackbar("API pública não está acessível no momento. Tente novamente mais tarde", "error");
          }
        });    
  }, []);
 

  useEffect(() => {
    if (tipo) {
      setMostrarSelectAno(true);
    } else {
      setMostrarSelectAno(false);
    }
  }, [tipo]);

  const handleFilterClick = () => { 
    try {
     if(selectedBioma || selectedRegiao || selectedUf || selectedCidade || selectedAno) {
        onCalculateDataChange(null);
        setLoading(true);
        setEnable(false);
        let apiPublica = BASE_URLS.WEB_API_PUBLIC + `calculate?tipo=${tipo.toLowerCase()}&`;

        if(tipo == 'Brasil') {
          apiPublica += `ano=${selectedAno}`;
        }

        if(selectedUf && !selectedCidade) {
           apiPublica += `uf=${selectedUf}&ano=${selectedAno}`;
        }

        if (selectedCidade) {
           apiPublica += `uf=${selectedUf}&filter=${selectedCidade}&ano=${selectedAno}`;
        }
        
        if(selectedRegiao) {
            apiPublica += `filter=${selectedRegiao}&ano=${selectedAno}`;
        }

        if(selectedBioma) {
            apiPublica += `filter=${selectedBioma}&ano=${selectedAno}`;
        }
        
        axios.get(apiPublica, headers).then(response => {
            setCalculo(response.data.calc);
            onCalculateDataChange(response.data.calc);
            onGeoJSON(response.data.geoJSON)
            setLoading(false);
            setEnable(true);
        }).catch((error) => {
          setLoading(false);
          setEnable(true);
          if(error.response.status === 404) {
            onGeoJSON('N');
            snackbarStore.openSnackbar("Novos locais e informações serão adicionados.", "warning");
          }
        });
      }
     } catch (error) {
        setLoading(false);
      }
    }

  useEffect(() => {
    if(tipo == 'UF'){   
      //limpar os campos para evitar possiveis erros
      setSelectedUf('');
      setSelectedCidade('');
      setCidades([]);
      setUfs([]);   

      axios.get(`${URL_IBGE}?orderBy=nome`).then(response => {
        const siglas = response.data.map((uf: any) => ({ sigla: uf.sigla, nome: uf.nome }));        
        setUfs(siglas);
        
        axios.get(BASE_URLS.WEB_API_PUBLIC + `getTiffsNames?ano=${selectedAno}`, headers)
            .then(response => {
              const filteredUfs = siglas.filter((uf: any) => response.data.includes(uf.sigla));
              setUfs(filteredUfs);
            })
            .catch(error => {
              console.error('Erro ao buscar os anos:', error)
            });
        });
    }    
  }, [selectedAno]);

  useEffect(() => {    
    if (selectedUf) {
      //limpar os campos para evitar possiveis erros
      setSelectedCidade('');
      setCidades([]);

      axios.get(`${URL_IBGE}/${selectedUf}/municipios`).then(response => {
        setCidades(response.data.map((cidade: any) =>  ({ nome: cidade.nome, codigo: cidade.id })));
      });
    } else {
      setCidades([]);
    }
  }, [selectedUf]);

  useEffect(() => {
    setSelectedBioma('');
    setSelectedUf('');
    setSelectedRegiao('');
    setSelectedCidade('');
    setSelectedAno('');
  }, [tipo]);

  useEffect(() => {
    setEnable(false);

    if(tipo == 'Brasil' && selectedAno) {
    setEnable(true);
    }
    
    if(selectedUf && !selectedCidade && selectedAno) {
    setEnable(true);
    }

    if (selectedCidade && selectedAno) {
    setEnable(true);
    }
    
    if(selectedRegiao && selectedAno) {
    setEnable(true);
    }

    if(selectedBioma && selectedAno) {
    setEnable(true);
    }       
  }, [tipo, selectedUf, selectedCidade, selectedRegiao, selectedBioma, selectedAno]);

  useEffect(() => {
    if (mostrarSelectAno) {
      axios.get(BASE_URLS.WEB_API_PUBLIC + 'getYears', headers)
        .then(response => {
          setAnos(response.data);
        })
        .catch(error => {
          console.error('Erro ao buscar os anos:', error)
        });
    }
  }, [mostrarSelectAno]);

  if (loading) {
    return <Loading position="static" />;
  }

  return (
    <div className="comboContainer">
        <div className="comboWrapper">
            <FormControl>
                <InputLabel id="tipo-label">Tipo</InputLabel>
                <Select
                labelId="tipo-label"
                id="tipo-select"
                value={tipo}
                label="Tipo"
                placeholder="Escolha o Tipo"
                onChange={(e) => setTipo(e.target.value)}
                >
                <MenuItem value="Bioma">Bioma</MenuItem>
                <MenuItem value="UF">UF</MenuItem>
                <MenuItem value="Regiao">Região</MenuItem>
                <MenuItem value="Brasil">Brasil</MenuItem>
                </Select>
            </FormControl>
        </div>

      {mostrarSelectAno && (
            <div className="comboWrapper">
              <FormControl fullWidth>
                <InputLabel id="ano-label">Ano</InputLabel>
                <Select
                  labelId="ano-label"
                  id="ano-select"
                  value={selectedAno}
                  label="Ano"
                  onChange={(e) => setSelectedAno(e.target.value)}
                >
                  {anos.map((ano) => (
                    <MenuItem key={ano} value={ano}>{ano}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>          
      )}

        
      {tipo === 'Bioma' && selectedAno && (
        <div className="comboWrapper">
        <FormControl>
          <InputLabel id="bioma-label">Bioma</InputLabel>
          <Select
            labelId="bioma-label"
            id="bioma-select"
            value={selectedBioma}
            label="Bioma"
            placeholder="Escolha o Bioma"
            onChange={(e) => setSelectedBioma(e.target.value)}
          >
            {biomas.map((bioma: any) => (
              <MenuItem key={bioma.cd_bioma} value={bioma.cd_bioma}>{bioma.nome}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
      )}
      
      {tipo === 'UF' && selectedAno && (
        <div className="comboWrapper">
        <FormControl>
          <InputLabel id="uf-label">UF</InputLabel>
          <Select
            labelId="uf-label"
            id="uf-select"
            value={selectedUf}
            label="UF"
            placeholder="Escolha a UF"
            onChange={(e) => setSelectedUf(e.target.value)}
          >
            {ufs.map((uf: any) => (
              <MenuItem key={uf.sigla} value={uf.sigla}>{uf.nome}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
      )}

      {tipo === 'UF' && selectedAno && selectedUf && (
        <div className="comboWrapper">
        <FormControl>
          <InputLabel id="cidade-label">Cidade</InputLabel>
          <Select
            labelId="cidade-label"
            id="cidade-select"
            value={selectedCidade}
            label="Cidade"
            placeholder="Escolha a Cidade"
            onChange={(e) => setSelectedCidade(e.target.value)}
          >
            <MenuItem value="">
              --
            </MenuItem>
            {cidades.map((cidade: any) => (
              <MenuItem key={cidade.codigo} value={cidade.codigo}>{cidade.nome}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
      )}

      {tipo === 'Regiao' && selectedAno && (
        <div className="comboWrapper">
        <FormControl>
          <InputLabel id="regiao-label">Região</InputLabel>
          <Select
            labelId="regiao-label"
            id="regiao-select"
            value={selectedRegiao}
            label="Região"
            placeholder="Escolha a Região"
            onChange={(e) => setSelectedRegiao(e.target.value)}
          >
            {regioes.map(regiao => (
              <MenuItem key={regiao} value={regiao}>{regiao}</MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
      )}      

      <Button variant="contained" color="primary" onClick={handleFilterClick} disabled={!enable}>
        Filtrar
      </Button>
    </div>
  );
};

export default DynamicCombo;