import { IconProps } from './Icon.types';

export function MilletIcon({
  width = '32',
  height = '32',
  color = 'rgb(0,0,0)',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 500 500"
      style={{ width: '100%', height: 'auto' }}
    >
      <g transform="matrix(1 0 0 1 250 250)" id="qs0qU9l4_5DDtTSS78toi">
        <g>
          <g
            transform="matrix(1 0 0 1 -6.6877720753 105.8988688014)"
            id="JrXOuKq7t1THwCUmAuUDD"
          >
            <path
              stroke={color}
              strokeWidth="1"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(0, -0.000005)"
              d="M -6.98681 32.71661 C 6.5511 7.445850000000004 6.98681 -14.666059999999995 6.98681 -32.7166"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(1 0 0 1 -23.6101547468 33.3051311252)"
            id="C3iWybl2CVc0CKCpdlGUe"
          >
            <path
              stroke={color}
              strokeWidth="1"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(0, 0.0000031009)"
              d="M 23.90919 23.63164 C 19.396549999999998 -2.0903799999999997 11.740619999999998 -18.335869999999996 -23.90919 -23.751029999999997 C -23.51012 7.593760000000003 -0.6516500000000001 25.392000000000007 23.90919 23.63164 z"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(0.9336928144 0 0 0.9243880485 -22.4916498906 -14.8440542272)"
            id="jUw4Z4yLL_uIkz_9eu7zg"
          >
            <path
              stroke={color}
              strokeWidth="1"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(0, 0.0000031009)"
              d="M 23.90919 23.63164 C 19.396549999999998 -2.0903799999999997 11.740619999999998 -18.335869999999996 -23.90919 -23.751029999999997 C -23.51012 7.593760000000003 -0.6516500000000001 25.392000000000007 23.90919 23.63164 z"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(0.8241717066 0 0 0.784083093 -19.3183260536 -59.3653261575)"
            id="QfWbw5e1e8bf4Ljelhdqe"
          >
            <path
              stroke={color}
              strokeWidth="1"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(0, 0.0000031009)"
              d="M 23.90919 23.63164 C 19.396549999999998 -2.0903799999999997 11.740619999999998 -18.335869999999996 -23.90919 -23.751029999999997 C -23.51012 7.593760000000003 -0.6516500000000001 25.392000000000007 23.90919 23.63164 z"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(-1 0 0 1 24.0273231168 33.3051305225)"
            id="L0AdeoTkmT5YzhzaNEqRw"
          >
            <path
              stroke={color}
              strokeWidth="1"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(0, 0.0000031009)"
              d="M 23.90919 23.63164 C 19.396549999999998 -2.0903799999999997 11.740619999999998 -18.335869999999996 -23.90919 -23.751029999999997 C -23.51012 7.593760000000003 -0.6516500000000001 25.392000000000007 23.90919 23.63164 z"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(-0.9336928144 0 0 0.9243880485 22.1458279729 -14.84405483)"
            id="9kcQDBFSJyANXgYmiotna"
          >
            <path
              stroke={color}
              strokeWidth="1"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(0, 0.0000031009)"
              d="M 23.90919 23.63164 C 19.396549999999998 -2.0903799999999997 11.740619999999998 -18.335869999999996 -23.90919 -23.751029999999997 C -23.51012 7.593760000000003 -0.6516500000000001 25.392000000000007 23.90919 23.63164 z"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(-0.8241717066 0 0 0.784083093 19.31915181 -59.3653267602)"
            id="7ugGzjYkdGSL4MpdY2-DL"
          >
            <path
              stroke={color}
              strokeWidth="1"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(0, 0.0000031009)"
              d="M 23.90919 23.63164 C 19.396549999999998 -2.0903799999999997 11.740619999999998 -18.335869999999996 -23.90919 -23.751029999999997 C -23.51012 7.593760000000003 -0.6516500000000001 25.392000000000007 23.90919 23.63164 z"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(0.5827774026 0.5827774026 -0.5544304721 0.5544304721 1.1165309833 -105.737254036)"
            id="XcAG9O_zxLvvQtbCa34__"
          >
            <path
              stroke={color}
              strokeWidth="1"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(0.000005, -0.0000013987)"
              d="M 23.19892 24.95768 C 18.68628 -0.7643400000000007 12.450890000000001 -19.647940000000002 -23.198929999999997 -25.063100000000002 C -22.79986 6.2816899999999976 -1.3619299999999974 26.718049999999995 23.19892 24.95768 z"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(1 0 0 1 -48.0830662061 -109.6809688014)"
            id="gvKuBcD5mhyh6PHEqdySu"
          >
            <path
              stroke={color}
              strokeWidth="1"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(0, -0.000005)"
              d="M 6.23003 -28.4345 C 0.15974000000000022 -13.09904 -5.91055 4.792340000000003 -6.23003 28.434510000000003"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(1 0 0 1 -66.4536732348 -69.4253777471)"
            id="OFlz5I_T6YaPwZC3qi4Qx"
          >
            <path
              stroke={color}
              strokeWidth="1"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(-0.000005, 0)"
              d="M -7.02875 -34.50479 C -6.3897699999999995 -14.37699 -2.55591 19.488819999999997 7.028760000000001 34.50479"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(1 0 0 1 49.0415344329 -109.6809688014)"
            id="xdDcjxEr8YtLGuZQXQnVP"
          >
            <path
              stroke={color}
              strokeWidth="1"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(0, -0.000005)"
              d="M -6.54952 -28.9345 C 2.0766800000000005 -7.2092600000000004 5.27157 4.014380000000003 6.54952 28.934510000000003"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(1 0 0 1 66.9329082348 -70.1546109739)"
            id="02ivg3ixhL1Sk0VEan0oY"
          >
            <path
              stroke={color}
              strokeWidth="1"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(0, 0)"
              d="M 6.54952 -34.27556 C 5.59105 -12.230829999999997 6.86901 0.7292300000000012 -6.54952 34.27556"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
