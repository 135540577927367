import { IconProps } from './Icon.types';

export function SoyIcon({
  width = '32',
  height = '32',
  color = 'rgb(0,0,0)',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 500 500"
      style={{ width: '100%', height: 'auto' }}
    >
      <g
        transform="matrix(1 0 0 1 220.933239451 248.0327978324)"
        id="z_hBIAKN5z-2kFDqYhd88"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          strokeLinecap="round"
          transform=" translate(0.0000055325, 0.000005)"
          d="M -67.40266 -95.07246 C -66.54681 -91.50642 -64.21424999999999 -85.51782 -67.40266 -74.50333 C -70.59107 -63.48884 -70.0525 -56.92132000000001 -67.40266 -49.855070000000005 C -62.185269999999996 -35.94203 -54.359179999999995 -38.260870000000004 -48.27222999999999 -29.565210000000004 C -42.185269999999996 -20.869560000000003 -43.34468999999999 -15.362310000000004 -36.098319999999994 -5.507240000000003 C -28.851939999999992 4.3478299999999965 -23.924409999999995 2.608699999999997 -17.257739999999995 10.144929999999997 C -10.591069999999995 17.68116 -12.909909999999995 28.4058 -5.373679999999995 37.39131 C 2.162550000000005 46.376819999999995 10.568350000000006 50.724639999999994 13.756750000000006 55.942029999999995 C 16.945160000000005 61.15942 13.177040000000005 70.72464 26.220520000000008 81.73912999999999 C 39.26400000000001 92.75361999999998 43.61182000000001 87.82609 52.59733000000001 91.01449 C 61.582840000000004 94.2029 63.03211 94.2029 67.37994 95.07245999999999 C 65.35095000000001 86.37680999999999 66.22052000000001 83.76811 67.37994 79.13042999999999 C 68.53936 74.49274999999999 71.72777 62.02897999999999 67.37994 53.62317999999999 C 63.03211 45.21737999999999 54.04661 39.71013999999999 47.09008 32.753609999999995 C 40.13356 25.797089999999994 43.61182 19.130419999999994 35.78573 8.695639999999994 C 27.95964 -1.7391400000000061 21.003120000000003 -0.8695800000000062 16.36544 -9.275370000000006 C 11.72776 -17.681170000000005 13.177029999999998 -24.347830000000005 6.220509999999999 -33.623200000000004 C -0.7360100000000012 -42.89856 -5.373690000000002 -42.318850000000005 -11.170790000000002 -49.85508 C -16.967890000000004 -57.391310000000004 -17.83746 -71.59421 -27.40267 -79.13044 C -36.96789 -86.66667 -43.3447 -86.66667 -50.591080000000005 -88.4058 C -57.83746000000001 -90.14493 -60.73601000000001 -92.17392 -67.40267 -95.07247 z"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 265.7239176013 220.7917581582)"
        id="ftCinhp7UNescsvE5PuCv"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          strokeLinecap="round"
          transform=" translate(0, -0.0000050812)"
          d="M -84.94696 -68.33142 C -76.54116 -68.33142 -69.87450000000001 -70.72997 -59.149860000000004 -65.51258 C -48.42522 -60.29519 -44.077400000000004 -47.54157 -37.120870000000004 -43.48359 C -30.164350000000002 -39.42562 -23.550000000000004 -42.90388 -15.723920000000003 -36.23721 C -7.8978300000000035 -29.570539999999998 -4.367250000000004 -18.55605 4.908109999999997 -13.918369999999996 C 14.183469999999996 -9.280689999999996 16.90016 -11.889379999999996 25.305959999999995 -6.382139999999996 C 33.71176 -0.8748899999999962 36.79217 9.559890000000005 42.58927 12.458440000000003 C 48.38637 15.356990000000003 61.25056 17.663880000000002 68.96608 23.762790000000003 C 76.6816 29.861700000000003 77.23741000000001 43.55063 78.69266 49.05787 C 80.14791000000001 54.56512 80.87703 59.68987 84.94696 64.58753 C 78.29523 64.9491 75.72240000000001 65.08955 70.13148000000001 65.79177 C 64.54056000000001 66.494 60.21366000000001 69.68838 49.54579000000001 68.69032 C 38.87793000000001 67.69226 24.24772000000001 55.09104 22.00956000000001 47.820750000000004 C 19.77140000000001 40.550470000000004 12.154490000000012 42.02365 2.299420000000012 38.835240000000006"
        />
      </g>
      <g
        transform="matrix(0.5429864405 -0.4397017498 0.5641162851 0.6966255964 181.2018439965 192.6354872537)"
        id="mKBBZkJe-Q_58iGaF2ub1"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="none"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          strokeLinecap="round"
          transform=" translate(0, 0)"
          d="M 0 -18.14433 C 10.01567 -18.14433 18.14433 -10.01567 18.14433 0 C 18.14433 10.01567 10.01567 18.14433 0 18.14433 C -10.01567 18.14433 -18.14433 10.01567 -18.14433 0 C -18.14433 -10.01567 -10.01567 -18.14433 0 -18.14433 z"
        />
      </g>
      <g
        transform="matrix(0.5429864405 -0.4397017498 0.5641162851 0.6966255964 209.1399883264 231.707948973)"
        id="ie5IS3ikSLKIDZKuXmeCy"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="none"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          strokeLinecap="round"
          transform=" translate(0, 0)"
          d="M 0 -18.14433 C 10.01567 -18.14433 18.14433 -10.01567 18.14433 0 C 18.14433 10.01567 10.01567 18.14433 0 18.14433 C -10.01567 18.14433 -18.14433 10.01567 -18.14433 0 C -18.14433 -10.01567 -10.01567 -18.14433 0 -18.14433 z"
        />
      </g>
      <g
        transform="matrix(0.5429864405 -0.4397017498 0.5641162851 0.6966255964 235.2224625532 270.161847677)"
        id="kpNxKENkcaiVh2MkIZNfG"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="none"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          strokeLinecap="round"
          transform=" translate(0, 0)"
          d="M 0 -18.14433 C 10.01567 -18.14433 18.14433 -10.01567 18.14433 0 C 18.14433 10.01567 10.01567 18.14433 0 18.14433 C -10.01567 18.14433 -18.14433 10.01567 -18.14433 0 C -18.14433 -10.01567 -10.01567 -18.14433 0 -18.14433 z"
        />
      </g>
      <g
        transform="matrix(0.5429864405 -0.4397017498 0.5641162851 0.6966255964 259.8616378109 305.5229313044)"
        id="pWBxUIuBebiTGIXTg1nUH"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="none"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          strokeLinecap="round"
          transform=" translate(0, 0)"
          d="M 0 -18.14433 C 10.01567 -18.14433 18.14433 -10.01567 18.14433 0 C 18.14433 10.01567 10.01567 18.14433 0 18.14433 C -10.01567 18.14433 -18.14433 10.01567 -18.14433 0 C -18.14433 -10.01567 -10.01567 -18.14433 0 -18.14433 z"
        />
      </g>
      <g
        transform="matrix(0.3598534959 -0.5988967898 0.7865334053 0.4725969488 311.4034948004 259.4373330633)"
        id="70U4loOR_T-fl3oibtK2H"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="none"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          strokeLinecap="round"
          transform=" translate(0, 0)"
          d="M 0 -18.14433 C 10.01567 -18.14433 18.14433 -10.01567 18.14433 0 C 18.14433 10.01567 10.01567 18.14433 0 18.14433 C -10.01567 18.14433 -18.14433 10.01567 -18.14433 0 C -18.14433 -10.01567 -10.01567 -18.14433 0 -18.14433 z"
        />
      </g>
      <g
        transform="matrix(0.3598534959 -0.5988967898 0.7865334053 0.4725969488 276.0426700581 234.4163672216)"
        id="rp5uGhOJ2RmRbDlvI7EXL"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="none"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          strokeLinecap="round"
          transform=" translate(0, 0)"
          d="M 0 -18.14433 C 10.01567 -18.14433 18.14433 -10.01567 18.14433 0 C 18.14433 10.01567 10.01567 18.14433 0 18.14433 C -10.01567 18.14433 -18.14433 10.01567 -18.14433 0 C -18.14433 -10.01567 -10.01567 -18.14433 0 -18.14433 z"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 239.7703158134 207.008959363)"
        id="M0ONaZCcYEHcUGCiYLNsP"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          strokeLinecap="round"
          transform=" translate(0.0000028157, 0.0000051675)"
          d="M -13.91563 -10.84592 C -10.20429 -13.938699999999999 -0.7197499999999994 -16.20674 7.5276700000000005 -9.402619999999999 C 15.77509 -2.5984999999999987 14.537980000000001 4.618 12.06375 8.535520000000002 C 9.58952 12.453050000000001 4.228700000000001 14.195160000000001 1.9606600000000007 13.782790000000002"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 208.0286847439 177.915621335)"
        id="40VSm018G2t4-e0HsCQpe"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          strokeLinecap="round"
          transform=" translate(-0.0000034769, 0.000005)"
          d="M -6.18565 -10.20619 C -1.8557499999999996 -7.525779999999999 10.197140000000001 -3.608249999999999 4.83632 10.20618"
        />
      </g>
    </svg>
  );
}
