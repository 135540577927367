import { Outlet } from 'react-router-dom';
import SideBar from '../SideBar';
import './PrivateRoutesLayout.scss';

export function PrivateRoutesLayout() {
  return (
    <div className="private-route-container">
      <SideBar />
      <Outlet />
    </div>
  );
}
