import { LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseOutline } from 'react-icons/io5';
import {
  MapContainer,
  Polygon,
  TileLayer,
  Tooltip,
  useMap,
} from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import { Error } from '../../../../components/Error';
import Loading from '../../../../components/Loading';
import { PrivateRoutes } from '../../../../domains/AppRoutes';
import { Glebe } from '../../../../domains/Glebe';
import { useGetSelectedFarm } from '../../../../store/FarmStore/FarmActions';
import { useGlebeStore } from '../../../../store/GlebeStore/glebeStore';
import './MapSection.scss';
import { useFarmStore } from '../../../../store/FarmStore/farmStore';
import { Farm } from '../../../../domains/Farm';

export function MapSection() {
  const { glebeList, glebeIsLoading, glebeIsError, setSelectedGlebe } =
    useGlebeStore();
  const { farmsIsLoading, farmsIsError } = useFarmStore();
  const navigate = useNavigate();

  const selectedFarmData = useGetSelectedFarm();
  const dataIsLoading = glebeIsLoading || farmsIsLoading;
  const showError = glebeIsError || farmsIsError;

  const carPolygon = getCarCoordinates(selectedFarmData);

  const carStyleOptions = { color: '#FFFFFF', weight: 2 };
  const glebeStyleOptions = { color: '#ece110', weight: 3 };

  const handleClickOnGlebe = (glebe: Glebe) => {
    setSelectedGlebe(glebe);
    navigate(PrivateRoutes.PLOT_CHART);
  };

  if (dataIsLoading) {
    return <LoadingSection />;
  }
  
  if (showError) {
    return <ErrorSection />;
  }

  // Limites do Brasil para LatLngBounds
  const brazilBounds = [
    [-33.747, -73.990], // Sudoeste
    [5.272, -34.792]    // Nordeste
  ] as LatLngTuple[];
  
  const bounds = carPolygon && carPolygon.length > 0 ? carPolygon : brazilBounds;
  return (
    <main className="ilpf-map-main-section">
      <MapContainer    
        bounds={bounds}  
        minZoom={2}
        scrollWheelZoom
        className="map-container"
      >
        <TileLayer
          url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          maxZoom={20}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />

        {carPolygon && 
          <>
            <RecenterButton originalBounds={carPolygon} />
            <MapInstructions />
            <Polygon pathOptions={carStyleOptions} positions={carPolygon} />
          </>
        }

        {carPolygon && 
          glebeList.map((glebe) => (
          <GlebePolygon
            key={glebe.id}
            glebe={glebe}
            glebeStyleOptions={glebeStyleOptions}
            onClick={handleClickOnGlebe}
          />
        ))}
      </MapContainer>
    </main>
  );
}

function LoadingSection() {
  return (
    <main className="ilpf-map-main-section">
      <Loading position="static" />
    </main>
  );
}

function ErrorSection({ errorMessageKey }: Readonly<{ errorMessageKey?: string }>) {
  return (
    <main className="ilpf-map-main-section">
      <Error position="static" errorMessageKey={errorMessageKey} />
    </main>
  );
}

function MapInstructions() {
  const [instructionsModalIsOpen, setInstructionsModalIsOpen] =
    useState<boolean>(true);
  const { t: translate } = useTranslation();

  return (
    instructionsModalIsOpen && (
      <div className="map-instructions-container">
        <span>{translate('map_instructions')}</span>
        <button
          className="map-instructions-close-btn"
          onClick={() => setInstructionsModalIsOpen(false)}
        >
          <IoCloseOutline />
        </button>
      </div>
    )
  );
}

function RecenterButton({
  originalBounds,
}: Readonly<{
  originalBounds: Array<LatLngTuple>;
}>) {
  const map = useMap();
  const onClick = () => {
    map.fitBounds(originalBounds);
  };

  return (
    <div className="leaflet-top leaflet-left">
      <div className="leaflet-bar leaflet-control reset-bounds-container">
        <a tabIndex={0} role="button" onKeyDown={onClick} onClick={onClick}>
          &#8982;
        </a>
      </div>
    </div>
  );
}

function GlebePolygon({
  glebe,
  glebeStyleOptions,
  onClick,
}: Readonly<{
  glebe: Glebe;
  glebeStyleOptions: { color: string; weight: number };
  onClick: (glebe: Glebe) => void;
}>) {
  return (
    <Polygon
      pathOptions={glebeStyleOptions}
      positions={glebe.the_geom.coordinates[0]}
      eventHandlers={{ click: () => onClick(glebe) }}
    >
      <Tooltip direction="bottom">{glebe.name}</Tooltip>
    </Polygon>
  );
}

const getCarCoordinates = (
  farmData: Farm | undefined,
): Array<LatLngTuple> | undefined => {
  try{
    const longLatCoordinates = farmData?.car?.the_geom?.coordinates[0][0];
    return longLatCoordinates?.map((longLat) => [longLat[1], longLat[0]]);
  }catch{
    return undefined;
  }
};
