import { Navigate } from 'react-router-dom';
import { DEFAULT_LOGGED_ROUTE, PublicRoutes } from '../../domains/AppRoutes';
import { getIsUserAuthenticated } from '../../helpers/UserHelpers';

export function NotMappedRoute() {
  const isUserAuthenticated = getIsUserAuthenticated();

  if (isUserAuthenticated) {
    return <Navigate to={DEFAULT_LOGGED_ROUTE} />;
  }

  return <Navigate to={PublicRoutes.LOGIN} />;
}
