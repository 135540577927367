import { create } from 'zustand';
import { LoadManagableRoleDataParams, ManagableRoleState, ManagableRoleStore } from './managableRoleStore.types';


const managableRolesStoreInitialState: ManagableRoleState = {
  managableRolesList: [],
  managableRolesIsLoading: false,
  managableRolesIsError: false,
};

export const useManagableRolesStore = create<ManagableRoleStore>((set) => ({
  ...managableRolesStoreInitialState,
  reset: () => set(managableRolesStoreInitialState),
  setManagableRolesIsLoading: () =>
    set((state: ManagableRoleState) => ({
      ...state,
      managableRolesIsLoading: true,
      managableRolesIsError: false,
    })),
  loadManagableRolesData: (changedProperties: LoadManagableRoleDataParams) =>
    set((state: ManagableRoleState) => ({
      ...state,
      managableRolesIsLoading: false,
      managableRolesIsError: false,
      ...changedProperties,
    })),
  setManagableRolesIsError: () =>
    set((state: ManagableRoleState) => ({
      ...state,
      managableRolesIsLoading: false,
      managableRolesIsError: true,
    }))
}));
