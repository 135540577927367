import { useTranslation } from 'react-i18next';
import './Error.scss';

interface ErrorProps {
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
  errorMessageKey?: string;
}

export function Error({
  position,
  errorMessageKey = 'generic_laod_data_error',
}: ErrorProps) {
  const { t: translate } = useTranslation();
  return (
    <div className="error" style={{ position }}>
      <span>{translate(errorMessageKey)}</span>
    </div>
  );
}
