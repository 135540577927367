import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FaMap } from 'react-icons/fa';
import { RiUserSettingsFill } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import { PrivateRoutes } from '../../../../domains/AppRoutes';
import { getIsUserAdmin } from '../../../../helpers/UserHelpers';
import './TabComponent.scss';
import { MdInsertChart } from 'react-icons/md';

function TabComponent() {
  const { t: translate } = useTranslation();
  

  return (
    <Box className="menu-list-container">
      <NavLink
        to={PrivateRoutes.MAP}
        className={({ isActive }) =>
          `menu-button ${isActive ? ' menu-button-active' : ''}`
        }
      >
        <FaMap size={32} />
        <span>{translate('map_page_button')}</span>
      </NavLink>

      { <NavLink
        to={PrivateRoutes.PLOT_CHART}
        className={({ isActive }) =>
          `menu-button ${isActive ? ' menu-button-active' : ''}`
        }
      >
        <MdInsertChart size={32} />
        <span>{translate('chart_page_button')}</span>
      </NavLink>}

      { getIsUserAdmin() && (
        <NavLink
          to={PrivateRoutes.USER_MANAGEMENT}
          className={({ isActive }) =>
            `menu-button ${isActive ? ' menu-button-active' : ''}`
          }
        >
          <RiUserSettingsFill size={32} />
          <span>{translate('user_management')}</span>
        </NavLink>
      )}
      
      {/* { <NavLink
        to={PrivateRoutes.SETTINGS}
        className={({ isActive }) =>
          `menu-button ${isActive ? 'tabComponent-content-active' : 'tabComponent-content'}`
        }
      >
        <MdDisplaySettings size={32} />
        <span>{translate('user_page_button')}</span>
      </NavLink>} */}      
    </Box>
  );
}

export default TabComponent;
