import { useTranslation } from 'react-i18next';
import { Error } from '../../components/Error';
import Loading from '../../components/Loading';
import { useManagableUserStore } from '../../store/ManagableUsersStore/managableUserStore';
import { ManageUsersTable } from './components/ManageUsersTable';
import { UserSearchInput } from './components/UserSearchInput';
import './userManagement.styles.scss';
import { useLoadManagableUsers } from '../../store/ManagableUsersStore/ManagableUserActions';

export function UserManagement() {
  useLoadManagableUsers();

  const { managableUsersIsError, managableUsersIsLoading } =
    useManagableUserStore();

  const { t: translate } = useTranslation();

  const loadPageContent = () => {
    if (managableUsersIsError) {
      return <Error position="static" />;
    }

    if (managableUsersIsLoading) {
      return <Loading position="static" />;
    }

    return <ManageUsersTable />;
  };

  return (
    <main className="ilpf-user-management-page">
      <header>
        <h1>{translate('user_management')}</h1>
        <div className="splitter" />
      </header>
      <div className="user-mgmt-table-search-container">
        <UserSearchInput />

        {loadPageContent()}
      </div>
    </main>
  );
}
