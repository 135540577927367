import { create } from 'zustand';
import { User } from '../../domains/User';
import { getStoredUserData } from '../../helpers/UserHelpers';
import { UserState, UserStore } from './UserStore.types';

const storedUserData = getStoredUserData();

const userStoreInitialState: UserState = {
  userIsLoading: false,
  userIsError: false,
  accessToken: storedUserData?.accessToken ?? null,
  userId: storedUserData?.userId ?? null,
  userName: storedUserData?.userName ?? null,
  userRoles: storedUserData?.userRoles ?? null,
};

export const useUserStore = create<UserStore>((set) => ({
  ...userStoreInitialState,
  reset: () => set(userStoreInitialState),
  setUserIsLoading: () =>
    set((state: UserState) => ({
      ...state,
      userIsLoading: true,
      userIsError: false,
    })),
  loadUserData: ({ accessToken, userId, userName }: User) =>
    set((state: UserState) => ({
      ...state,
      userIsLoading: false,
      userIsError: false,
      accessToken,
      userId,
      userName,
    })),
  setUserIsError: () =>
    set((state: UserState) => ({
      ...state,
      userIsLoading: false,
      userIsError: true,
    })),
}));
