import ApiHelper from '../../helpers/ApiHelper';
import { BASE_URLS, URLS } from '../apiConstants';
import {
  ChangePasswordParams,
  LoginGoogleParams,
  LoginGoogleResponse,
  LoginParams,
  LoginResponse,
  PassResetGenerateTokenParams,
  PassResetGenerateTokenResponse,
  ResetPasswordParams,
} from './AccountService.types';

const AccountService = {
  login: (data: LoginParams) =>
    ApiHelper.callApi<LoginResponse>({
      baseUrl: BASE_URLS.WEB_API,
      headers: {},
      method: 'POST',
      url: URLS.LOGIN,
      data,
    }),
  resetPassword: (data: ResetPasswordParams) =>
    ApiHelper.callApi<void>({
      baseUrl: BASE_URLS.WEB_API,
      headers: {},
      method: 'POST',
      url: URLS.RESET_PASSWORD,
      data,
    }),
  passResetGenerateToken: (data: PassResetGenerateTokenParams) =>
    ApiHelper.callApi<PassResetGenerateTokenResponse>({
      baseUrl: BASE_URLS.WEB_API,
      headers: {},
      method: 'POST',
      url: URLS.GENERATE_TOKEN,
      data,
    }),
  changePassword: ({ data, resetToken }: ChangePasswordParams) =>
    ApiHelper.callApi<void>({
      baseUrl: BASE_URLS.WEB_API,
      headers: { Authorization: `Bearer ${resetToken}` },
      method: 'POST',
      url: URLS.CHANGE_PASSWORD,
      data,
    }),
  loginGoogle: (data: LoginGoogleParams) =>
    ApiHelper.callApi<LoginGoogleResponse>({
      baseUrl: BASE_URLS.WEB_API,
      headers: {},
      method: 'POST',
      url: URLS.LOGIN_GOOGLE,
      data,
    }),
};

export default AccountService;
