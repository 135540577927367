import './Loading.scss';
import loading from './loading.svg';

interface LoadingProps {
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
}

function Loading({ position }: LoadingProps) {
  return (
    <div className="loading" style={{ position }}>
      <img src={loading} alt="loading..." title="loading..." />
    </div>
  );
}

export default Loading;
