import { Alert, Box, Button, Snackbar, TextField } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AccountBanner from '../../../../components/AccountBanner';
import LoginNavigation from '../../../../components/LoginNavigation';
import AccountService from '../../../../services/AccountService';
import '../ForgotPassword.scss';
import { CurrentComponent, EmailViewProps } from '../ForgotPassword.types';

interface EmailFormValues {
  email: string;
}

function EmailView({ nextComponent }: EmailViewProps) {
  const { t } = useTranslation();
  const service = AccountService;
  const [emailViewError, setEmailViewError] = useState<null | string>(null);
  const [open, setOpen] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EmailFormValues>();

  function onSubmit(data: EmailFormValues) {
    const { email } = data;
    service
      .resetPassword({ email })
      .then(() => {
        nextComponent(CurrentComponent.CODE_VIEW, email);
      })
      .catch((error) => {
        setOpen(true);
        if (error.data && error.data.message) {
          setEmailViewError(error.data.message);
        } else {
          setEmailViewError('Ocorreu um erro');
        }
      });
  }

  const handleClose = () => {
    setOpen(false);
    setEmailViewError('');
  };

  return (
    <Box className="ilpf-forgot-password-screen">
      <Box className="ilpf-forgot-password-content">
        <LoginNavigation />
        <Box className="ilpf-forgot-password-main-box">
          <Box className="ilpf-forgot-password-title">
            <h1>{t('email_view_title')}</h1>
          </Box>
          <Box className="ilpf-forgot-password-text">
            <span>{t('email_view_text')}</span>
          </Box>
          <Box className="ilpf-forgot-password-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="email">{t('login_email')}</label>
              <TextField
                id="email"
                {...register('email', {
                  required: true,
                  pattern: /\S+@\S+\.\S+/,
                })}
                error={!!errors.email}
                variant="outlined"
              />
              {errors.email && errors.email.type === 'required' && (
                <span className="error-message">
                  {t('error_required_email')}
                </span>
              )}
              {errors.email && errors.email.type === 'pattern' && (
                <span className="error-message">
                  {t('error_invalid_email')}
                </span>
              )}
              <Button
                type="submit"
                className="forgot-password-button"
                variant="contained"
              >
                {t('email_view_button')}
              </Button>
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert severity="error" onClose={handleClose}>
                  {emailViewError}
                </Alert>
              </Snackbar>
            </form>
          </Box>
        </Box>
      </Box>
      <AccountBanner />
    </Box>
  );
}

export default EmailView;
