import 'leaflet/dist/leaflet.css';
import { useGetFarms } from '../../store/FarmStore/FarmActions';
import { useLoadGlebesAction } from '../../store/GlebeStore/glebeActions';
import './MapPage.scss';
import { MapSection } from './components/MapSection';
import { SideCarMenu } from './components/SideCarMenu';

function MapPage() {
  useGetFarms();
  useLoadGlebesAction();

  return (
    <div className="ilpf-map-page-container">
      <MapSection />
      <SideCarMenu />
    </div>
  );
}

export default MapPage;
