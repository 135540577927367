export enum CurrentComponent {
  EMAIL = 0,
  CODE_VIEW = 1,
  NEW_PASS = 2,
}

export type NextComponent = (
  component: CurrentComponent,
  currentEmail: string,
  token?: string,
) => void;

export interface CodeViewProps {
  email: string;
  nextComponent: NextComponent;
}

export interface EmailViewProps {
  nextComponent: NextComponent;
}

export interface NewPassViewProps {
  nextComponent: NextComponent;
  email: string;
  resetToken: string;
}
