import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Error } from '../../components/Error';
import Loading from '../../components/Loading';
import { useLoadGlebeEventsAction } from '../../store/GlebeEventsStore/glebeEventsActions';
import { useGlebeEventsStore } from '../../store/GlebeEventsStore/glebeEventsStore';
import { useGlebeStore } from '../../store/GlebeStore/glebeStore';
import './PlotGraph.scss';
import BarChartComponent from './components/BarChart/BarChart';
import { LoadAllGlebeEvents } from './components/LoadAllGlebeEvents';
import { buildDataSeries } from './utils/buildDataSeries';

function PlotGraph() {
  useLoadGlebeEventsAction();
  const {
    glebeEventsList,
    fertilizerKeysData,
    glebeEventsIsLoading,
    glebeEventsIsError,
  } = useGlebeEventsStore();

  const { selectedGlebe } = useGlebeStore();
  const { t: translate } = useTranslation();

  const {
    livestockDataSeries,
    productivityDataSeries,
    forestDataSeries,
    fertilizerDataSeries,
  } = buildDataSeries(translate, fertilizerKeysData);
  
  const title = `${selectedGlebe?.name}: ${translate('comparative_charts')}`;

  if (glebeEventsIsLoading) {
    return (
      <main className="ilpf-plot-graph">
        <Loading position="static" />
      </main>
    );
  }

  if (glebeEventsIsError) {
    return (
      <main className="ilpf-plot-graph">
        <Error position="static" />
      </main>
    );
  }

  if (!selectedGlebe) {
    return (
      <main className="ilpf-plot-graph">
        <Error position="static" errorMessageKey="no_selected_glebe" />
      </main>
    );
  }
  console.log(glebeEventsList.map(x => x.forest.hasForest));
  return (
    <main className="ilpf-plot-graph">
      <Box className="ilpf-section">
        <span className="title">{title}</span>
        <LoadAllGlebeEvents />

        <Box className="ilpf-section-graph">
          <BarChartComponent
            id="livestock-chart"
            title={translate('livestock')}
            dataSeries={livestockDataSeries}
            data={glebeEventsList}
            getAdditionalInfoKey={() => [
              'cattleHeads.animalType',
              'cattleHeads.fooder',
            ]}
            yAxisLabel={translate('livestock_label')}
          />
        </Box>

        <Box className="ilpf-section-graph">
          <BarChartComponent
            id="productivity-chart"
            title={translate('productivity')}
            dataSeries={productivityDataSeries}
            data={glebeEventsList}
            getAdditionalInfoKey={(dataKey: string) => [
              `${dataKey.replace(/\.[^.]+$/, '')}.crop`,
            ]}
            yAxisLabel={translate('productivity_label')}
            unavailableDataMessage={translate(
              'productivity_unavailable_message',
            )}
          />
        </Box>

        <Box className="ilpf-section-graph">
          <BarChartComponent
            id="fertilizer-chart"
            title={translate('fertilizer')}
            dataSeries={fertilizerDataSeries}
            data={glebeEventsList}
            getAdditionalInfoKey={(dataKey: string) => [
              `${dataKey.replace(/\.[^.]+$/, '')}.crop`,
            ]}
            yAxisLabel={translate('fertilizer_label')}
          />
        </Box>
        {glebeEventsList.some(x => x.forest.hasForest) &&
          <Box className="ilpf-section-graph">
            <BarChartComponent
              id="forest-chart"
              title={translate('forest')}
              dataSeries={forestDataSeries}
              data={glebeEventsList}
              getAdditionalInfoKey={() => ['forest.additionalInfo']}
            />
          </Box>
        }
      </Box>
    </main>
  );
}

export default PlotGraph;
