export const PublicRoutes = {
  MAP_PUBLIC: '/public',
  LOGIN: '/login',
  CREATE_ACCOUNT: '/create-account',
  FORGOT_PASSWORD: '/forgot-password',
  NOT_AUTHORIZED: '/not-authorized',
  TERMS: '/terms',
};

export const PrivateRoutes = {
  MAP: '/map',
  PLOT_CHART: '/plot-chart',
  SETTINGS: '/settings',
  USER_MANAGEMENT: '/user-management',
};
export const DEFAULT_LOGGED_ROUTE = PrivateRoutes.MAP;
