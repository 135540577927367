import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Loading from './components/Loading';
import { NotMappedRoute } from './components/NotMappedRoute';
import { PrivateRoutesLayout } from './components/PrivateRoutesLayout';
import ProtectedRoute from './components/ProtectedRoute';
import { PrivateRoutes, PublicRoutes } from './domains/AppRoutes';
import { getIsUserAdmin, getIsUserAuthenticated } from './helpers/UserHelpers';
import CreateAccount from './screens/Account/CreateAccount';
import ForgotPassword from './screens/Account/ForgotPassword';
import Login from './screens/Account/Login';
import MapPage from './screens/Map';
import NotAuthorized from './screens/NotAuthorized';
import PlotGraph from './screens/PlotGraph';
import Terms from './screens/Terms';
import { UserManagement } from './screens/UserManagement';
import SnackbarComponent from './components/Notification';
import useSnackbarStore from './store/Notification/NotificationStore';
import MapPublic from './screens/MapPublic';

function App() {
  const snackbarStore = useSnackbarStore();
  return (
    <Suspense fallback={<Loading />}>
      <SnackbarComponent
          open={snackbarStore.isOpen}
          message={snackbarStore.message}
          onClose={snackbarStore.closeSnackbar}
          severity={snackbarStore.severity}
        />
      <Routes>
        <Route path={PublicRoutes.MAP_PUBLIC} element={<MapPublic />} />
        <Route path={PublicRoutes.LOGIN} element={<Login />} />
        <Route path={PublicRoutes.CREATE_ACCOUNT} element={<CreateAccount />} />
        <Route
          path={PublicRoutes.FORGOT_PASSWORD}
          element={<ForgotPassword />}
        />
        <Route path={PublicRoutes.NOT_AUTHORIZED} element={<NotAuthorized />} />
        <Route path={PublicRoutes.TERMS} element={<Terms />} />

        <Route element={<PrivateRoutesLayout />}>
          <Route
            path={PrivateRoutes.PLOT_CHART}
            element={
              <ProtectedRoute>
                <PlotGraph />
              </ProtectedRoute>
            }
          />
          <Route
            path={PrivateRoutes.MAP}
            element={
              <ProtectedRoute>
                <MapPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={PrivateRoutes.SETTINGS}
            element={
              <ProtectedRoute>
                <h1>Settings page</h1>
              </ProtectedRoute>
            }
          />
          <Route
            path={PrivateRoutes.USER_MANAGEMENT}
            element={
              <ProtectedRoute
                isAllowed={() => getIsUserAuthenticated() && getIsUserAdmin()}
              >
                <UserManagement />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="*" element={<NotMappedRoute />} />
      </Routes>
    </Suspense>
  );
}

export default App;
