import { CornIcon } from './chartIcons/CornIcon';
import { CottonIcon } from './chartIcons/CottonIcon';
import { IconProps } from './chartIcons/Icon.types';
import { MilletIcon } from './chartIcons/MilletIcon';
import { OthersIcon } from './chartIcons/OthersIcon';
import { SorghumIcon } from './chartIcons/SorghumIcon';
import { SoyIcon } from './chartIcons/SoyIcon';
import { SunFlowerIcon } from './chartIcons/SunFlowerIcon';

const cropIconMap = {
  soja: SoyIcon,
  milho: CornIcon,
  milheto: MilletIcon,
  sorgo: SorghumIcon,
  algodão: CottonIcon,
  girassol: SunFlowerIcon,
  outros: OthersIcon,
};

export function getCropIcon(
  cropValue: string | undefined,
): (({ width, height, color }: IconProps) => JSX.Element) | null {
  const standardCropKey = cropValue?.toLowerCase()?.replaceAll(' ', '_');

  if (!standardCropKey) {
    return null;
  }

  return (
    cropIconMap?.[standardCropKey as keyof typeof cropIconMap] ??
    cropIconMap.outros
  );
}
