import './AccountBanner.scss';

interface AccountBannerProps {
  screen?: boolean;
}

function AccountBanner({ screen = false }: AccountBannerProps) {
  if (!screen) {
    return (
      <div className="ilpf-account-banner">
        <div className="ilpf-account-logo">
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/rede-ilpf-white-logo.svg`}
            alt="ILPF Logo"
            title="ILPF Logo"
          />
        </div>
      </div>
    );
  }
  return (
    <div className="ilpf-account-banner">
      <div className="ilpf-account-logo">
        <img
          src={`${process.env.PUBLIC_URL}/resources/images/rede-ilpf-white-logo.svg`}
          alt="ILPF Logo"
          title="ILPF Logo"
        />
      </div>
      <div className="ilpf-account-with-screen">
        <img
          src={`${process.env.PUBLIC_URL}/resources/images/phone.svg`}
          alt="screen"
          title="screen"
        />
      </div>
    </div>
  );
}

export default AccountBanner;
