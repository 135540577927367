import { IconProps } from './Icon.types';

export function SunFlowerIcon({
  width = '32',
  height = '32',
  color = 'rgb(0,0,0)',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 500 500"
      style={{ width: '100%', height: 'auto' }}
    >
      <g
        transform="matrix(1 0 0 1 250.6289322383 118.6638869494)"
        id="-RrtBJXGEOIlvq26Z2yXi"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.9135454576 0.4067366431 -0.4067366431 0.9135454576 285.1650147125 125.9834472164)"
        id="1HlCP6xfP9Dce6UTLUbzW"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.6691306064 0.7431448255 -0.7431448255 0.6691306064 314.587061799 146.3301237725)"
        id="L7VOyC1Zpg1x71ZmN7ixz"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.3746065934 0.9271838546 -0.9271838546 0.3746065934 333.4530411804 175.2334866261)"
        id="nbGSbbPEcENt2uYJcY7YX"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0 -1 1 0 159.3166868055 208.6572506454)"
        id="o9JKmAJTlI_7tl1KNw24v"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.4067366431 -0.9135454576 0.9135454576 0.4067366431 166.6362470725 174.1211681711)"
        id="JB5Yb9Kw34yZfZ2_J7SKs"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7431448255 -0.6691306064 0.6691306064 0.7431448255 186.9829236286 144.6991210847)"
        id="WGXgfniofyZ_k3T6S-Mjf"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.9271838546 -0.3746065934 0.3746065934 0.9271838546 215.8862864822 125.8331417033)"
        id="UfLjDaUkEj7lDUPeyYTA5"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-1 0 0 -1 249.1074134124 300.0885449811)"
        id="9A19HoJpRG-LzT7OvS0B8"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-0.9135454576 -0.4067366431 0.4067366431 -0.9135454576 214.5713309382 292.7689847141)"
        id="7TN0dSbUkGSKXVgAk4JGx"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-0.6691306064 -0.7431448255 0.7431448255 -0.6691306064 185.1492838517 272.4223081581)"
        id="2iSnGblUAPM_UNOhxa9tb"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-0.3746065934 -0.9271838546 0.9271838546 -0.3746065934 166.2833044703 243.5189453044)"
        id="wlftk4SZFy-SI-fqsx-hE"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0 1 -1 0 340.4196588452 210.0951812851)"
        id="zwo4KqcmhyVUxnL_tK0XZ"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-0.4067366431 0.9135454576 -0.9135454576 -0.4067366431 333.1000985782 244.6312637594)"
        id="Vvuikwu75W-0Kbf81BuMv"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-0.7431448255 0.6691306064 -0.6691306064 -0.7431448255 312.7534220221 274.0533108458)"
        id="kC-uuXznUMUkRHhCiHfhG"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-0.9271838546 0.3746065934 -0.3746065934 -0.9271838546 283.8500591685 292.9192902272)"
        id="g63G81h7FMCVQtztza2P1"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000041442, 0)"
          d="M -13.01501 20.71923 C -16.17385 6.2788 -9.6384 -13.29655 -0.714220000000001 -20.71923 C 6.1480999999999995 -12.47181 15.338259999999998 -2.769259999999999 13.609539999999999 20.71923"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1.311829213 0 0 1.311829213 249.2958634942 210.1126470025)"
        id="9zVwe2h0hOxOjXWvq9PYR"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 0 -53.65297 C 29.61644 -53.65297 53.65297 -29.616440000000004 53.65297 0 C 53.65297 29.61644 29.616440000000004 53.65297 0 53.65297 C -29.61644 53.65297 -53.65297 29.616440000000004 -53.65297 0 C -53.65297 -29.61644 -29.616440000000004 -53.65297 0 -53.65297 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 319.5433787443 331.7286186258)"
        id="jwstdValyY5bu1EgjHqYP"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -50.22831 34.7032 C -44.06393 13.013700000000004 8.675800000000002 -32.876709999999996 50.22831 -34.70319 C 43.83562 -10.9589 7.30594 28.76713 -50.22831 34.7032 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-1 0 0 1 180.4566207534 331.7286172788)"
        id="RpSOsfNc82CYcjsNcIQGp"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, -0.000005)"
          d="M -50.22831 34.7032 C -44.06393 13.013700000000004 8.675800000000002 -32.876709999999996 50.22831 -34.70319 C 43.83562 -10.9589 7.30594 28.76713 -50.22831 34.7032 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1.0467173523 249.9009911126 361.4899558761)"
        id="fs__B9zOEAFvJHXGSdfK1"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 0 -38.75486 L 0 38.754870000000004"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 249.9999994977 377.3351286457)"
        id="URQHODT7aWc_wpWQw4TiE"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 18.81507 -10.40332 L -0.09900999999999982 10.403309999999998 L -18.81507 -10.40332"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 240.1155043899 199.680137298)"
        id="uhENAXaRd6zs5y-3A41Qt"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 38.68994 -29.02487 C 25.112920000000003 -41.55751 -0.9967400000000026 -43.38518 -20.056800000000003 -29.02487 C -39.11685 -14.66456 -45.90536 21.10568 -29.19518 39.12134"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-1 0 0 -1 260.0185755609 220.2763497601)"
        id="ilSZ2yegbn-FhF4bMCcSE"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000042627, 0.0000020998)"
          d="M 38.68994 -29.02487 C 25.112920000000003 -41.55751 -0.9967400000000026 -43.38518 -20.056800000000003 -29.02487 C -39.11685 -14.66456 -45.90536 21.10568 -29.19518 39.12134"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}
