import { useEffect } from 'react';
import GlebeService from '../../services/GlebeService';
import { useFarmStore } from '../FarmStore/farmStore';
import { useGlebeStore } from './glebeStore';

export function useLoadGlebesAction() {
  const { selectedFarmDisplayId } = useFarmStore();

  useEffect(() => {
    if (selectedFarmDisplayId) {
      loadGlebes(selectedFarmDisplayId);
    }
  }, [selectedFarmDisplayId]);
}

async function loadGlebes(carId: string) {
  const { setGlebeIsLoading, setGlebeIsError, loadGlebeData } =
    useGlebeStore.getState();

  try {
    setGlebeIsLoading();    

    const response = await GlebeService.getGlebeByCar(carId);
    loadGlebeData(response);
  } catch (error) {
    console.log(error);
    setGlebeIsError();
  }
}
