import { useEffect } from 'react';
import { useFarmStore } from './farmStore';
import { Farm } from '../../domains/Farm';
import { USER_DATA_STORAGE_KEY } from '../../domains/UserDataKey';
import FarmService from '../../services/FarmService';

export const getFarms = async () => {
  useFarmStore.setState({ farmsIsLoading: true });

  try {
    const payload: Farm[] = await FarmService.getAll();

    const userDataString = localStorage.getItem(USER_DATA_STORAGE_KEY);
    const userData = userDataString ? JSON.parse(userDataString) : null;

    const data = userData?.userRoles[0]?.name !== "admin" 
        ? payload.filter(x => x.user_id === userData?.userId) 
        : payload;

    useFarmStore.setState({
      farms: data,
      selectedFarmDisplayId: data[0]?.car_id,
    });
  } catch (error) {
    useFarmStore.setState({ farmsIsError: true });
  } finally {
    useFarmStore.setState({ farmsIsLoading: false, farmsIsError: false });
  }
};

export function useGetFarms() {
  useEffect(() => {
    getFarms();
  }, []);
}

export function useGetSelectedFarm() {
  const { farms, selectedFarmDisplayId } = useFarmStore();

  const selectedFarm = farms.find((farm) => farm.car_id === selectedFarmDisplayId);

  return selectedFarm;
}
