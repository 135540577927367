import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface SnackbarComponentProps {
    open: boolean;
    message: string;
    onClose: () => void;
    severity: AlertProps['severity']; // Tipo específico para a propriedade severity
  }

const SnackbarComponent = ({ open, message, onClose, severity } : SnackbarComponentProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity={severity} // 'success', 'info', 'warning' ou 'error'
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackbarComponent;
