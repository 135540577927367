import { Props } from 'recharts/types/component/Label';
import { IconProps } from '../../../../utils/chartIcons/Icon.types';

interface CustomLabelProps extends Props {
  getCustomLabelIcon: (
    key: string | undefined,
  ) => (({ width, height, color }: IconProps) => JSX.Element) | null;
  fill: string;
}

export function CustomLabel({
  getCustomLabelIcon,
  fill,
  ...props
}: CustomLabelProps) {
  const { x, y, width } = props;

  const ICON_WIDTH = 60;

  const IconComponent = getCustomLabelIcon(props.value as string | undefined);

  if (!IconComponent || !props.value || !x || !y || !width) {
    return null;
  }

  const convertedX = typeof x === 'number' ? x : parseInt(x);
  const convertedY = typeof y === 'number' ? y : parseInt(y);
  const convertedWidth = typeof width === 'number' ? width : parseInt(width);

  return (
    <g
      transform={`translate(${convertedX + convertedWidth / 2 - ICON_WIDTH / 2},${convertedY - ICON_WIDTH + 10})`}
    >
      <IconComponent width={ICON_WIDTH} height={ICON_WIDTH} color={fill} />
    </g>
  );
}
