import { IconProps } from './Icon.types';

export function CornIcon({
  width = '32',
  height = '32',
  color = 'rgb(0,0,0)',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 500 500"
      style={{ width: '100%', height: 'auto' }}
    >
      <g
        transform="matrix(1 0 0 1 223.8267148014 264.2407440231)"
        id="w10CjTIc4_28fG8uNMxSx"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(-0.000005, -0.0000025627)"
          d="M 56.85921 61.57153 C 44.67509 65.18164 29.48595 70.60697 12.635379999999998 66.68924 C -4.215180000000004 62.77151 -17.599280000000004 51.19247 -24.368230000000004 47.582359999999994 C -31.137180000000004 43.972249999999995 -44.863220000000005 40.416799999999995 -56.8592 40.81340999999999 C -50.31906 33.09743999999999 -39.54721 15.498159999999991 14.043840000000003 8.619889999999991 C -0.6597199999999965 -3.2807600000000097 -25.865649999999995 -34.20566000000001 -17.291649999999997 -68.05042 C -5.107529999999997 -49.097350000000006 10.830330000000004 -50.34182 12.635390000000005 -50.79309000000001"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 151.5010189272 247.8490656921)"
        id="QqVvvNH0v7PaQGEzDTQUe"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(-0.0000065202, 0.0000020747)"
          d="M 52.63682 -35.5484 C 44.18612 -37.66108 14.073300000000003 -52.15878 -22.898529999999994 -46.17286 C -59.87035999999999 -40.18694 -56.34922999999999 -14.04625 -44.377399999999994 2.1509300000000025 C -32.40557 18.348110000000002 6.205940000000005 41.977470000000004 24.5158 47.611270000000005"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 279.1019823006 186.5641932574)"
        id="_NvpZWbSQLearmer3GQqA"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0.0000028899, -0.0000029662)"
          d="M -31.48542 54.56257 C -37.11922 39.77384 -53.66852 7.717649999999999 -44.865700000000004 -30.310520000000004 C -36.06288000000001 -68.33869000000001 -9.302320000000002 -51.085170000000005 -2.9642900000000054 -47.91615 C 3.3737399999999944 -44.74714 36.83241999999999 -18.2859 47.395799999999994 14.812689999999996"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 304.323822592 276.4647884452)"
        id="FPjCxGXyM-Mz2NfUKj0Le"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0.0000029001, 0.0000023727)"
          d="M 2.44767 -8.23929 C 2.09556 -20.56323 3.50401 -70.21112 34.13781 -82.88718 C 29.56035 -71.26746 34.242740000000005 -59.685050000000004 40.12373 -52.9576 C 46.00471 -46.23015 92.94063 -4.366050000000001 54.20824 49.15508 C 65.47585000000001 58.31001 62.306830000000005 66.05649 58.785700000000006 68.87339 C 55.264570000000006 71.69029 46.461760000000005 79.78888 36.250490000000006 82.25367 C 26.039220000000007 84.71846 22.665980000000005 79.43677 21.257530000000006 75.91564 C 19.849080000000008 72.39451 19.349080000000008 65.00014999999999 12.658940000000007 64.29592 C -18.326979999999992 61.47902 -46.848099999999995 29.436769999999996 -50.01711999999999 3.380429999999997 C -53.18612999999999 -22.675910000000002 -66.56642 -25.140700000000002 -69.38332 -34.647740000000006 C -32.05938 -39.57732000000001 -11.63684 -21.267460000000007 2.4476700000000022 -8.239290000000008 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 133.8841714547 222.9790220128)"
        id="IZyYqFgv92dOdernPAsYW"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 153.3912137082 230.1620918492)"
        id="FHJ7j8jMcSIjvhqiG8IVj"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 172.2491990253 239.1480360973)"
        id="GhUqaiG1innsf44uFQ0E2"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 191.0520159267 245.56338)"
        id="b4xOG3sdx_j78OTsJiU6z"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 126.6306502575 243.0212760973)"
        id="-3AZbWW2SOnCRQwZ01kIS"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 145.137692511 250.2043459337)"
        id="12hxUbckmUaajUjyMy-da"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 163.9956778281 258.1902901818)"
        id="GQyo8V0C0Fc77cMh0At_o"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 183.7984947295 265.6056340845)"
        id="VZT5ECsbXOde1WQwAlvOx"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7193398003 0.6946583705 -0.6946583705 0.7193398003 268.8631339473 160.4094030194)"
        id="QNkUxVlUTd64iNB10JwsF"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7193398003 0.6946583705 -0.6946583705 0.7193398003 277.1862064393 178.4325428552)"
        id="LISbek6pTjkMCDBVeJBqm"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7193398003 0.6946583705 -0.6946583705 0.7193398003 285.2040028141 197.2770077466)"
        id="h2vNevY6LT7GJX--Au4HD"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7193398003 0.6946583705 -0.6946583705 0.7193398003 294.2978264583 216.3673522683)"
        id="4VBuCDkaH4cV4yp3VMDlz"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7193398003 0.6946583705 -0.6946583705 0.7193398003 249.7228678949 169.7878748559)"
        id="uIXaSMJyl7BXhOrUw-v2P"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7193398003 0.6946583705 -0.6946583705 0.7193398003 258.0459403869 187.8110146917)"
        id="aL-4aUiHsMUh423ScdTFw"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7193398003 0.6946583705 -0.6946583705 0.7193398003 266.0637367618 206.6554795832)"
        id="3rYaJjYYuIYN3mrmDm_nn"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7193398003 0.6946583705 -0.6946583705 0.7193398003 275.1575604059 225.7458241048)"
        id="nAGdxNEzdIT8d2BgPJZ2X"
      >
        <path
          stroke={color}
          strokeWidth="5"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          transform=" translate(0, 0)"
          d="M -5.28169 -1.93662 L 5.28169 1.93662"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}
