import { create } from 'zustand';
import {
  GlebeEventsState,
  GlebeEventsStore,
  LoadGlebeEventsData,
} from './glebeEventsStore.types';

const glebeEventsStoreInitialState: GlebeEventsState = {
  glebeEventsIsLoading: false,
  glebeEventsIsError: false,
  allGlebeEventsIsActive: false,
  glebeEventsList: [],
  fertilizerKeysData: {},
};

export const useGlebeEventsStore = create<GlebeEventsStore>((set) => ({
  ...glebeEventsStoreInitialState,
  reset: () => set(glebeEventsStoreInitialState),
  setGlebeEventsIsLoading: () =>
    set((state: GlebeEventsState) => ({
      ...state,
      glebeEventsIsLoading: true,
      glebeEventsIsError: false,
      allGlebeEventsIsActive: false,
    })),
  loadGlebeEventsData: ({
    glebeEventsList,
    fertilizerKeysData,
    allGlebeEventsIsActive,
  }: LoadGlebeEventsData) =>
    set((state: GlebeEventsState) => ({
      ...state,
      glebeEventsIsLoading: false,
      glebeEventsIsError: false,
      glebeEventsList,
      fertilizerKeysData,
      allGlebeEventsIsActive,
    })),
  setGlebeEventsIsError: () =>
    set((state: GlebeEventsState) => ({
      ...state,
      glebeEventsIsLoading: false,
      glebeEventsIsError: true,
      allGlebeEventsIsActive: false,
    })),
}));
