import { TFunction } from 'i18next';
import { FertilizerKeyData } from '../../../store/GlebeEventsStore/glebeEventsStore.types';
import { ForestIcon } from './chartIcons/ForestIcon';
import { IconProps } from './chartIcons/Icon.types';
import { LivestockIcon } from './chartIcons/LivestockIcon';
import { generateSeriesColor } from './generateSeriesColor';
import { getCropIcon } from './getCropIcon';

export interface DataSerie {
  dataKey: string;
  fill: string;
  name: string;
  customLabelDataKey?: string;
  getCustomLabelIcon?: (
    crop: string | undefined,
  ) => (({ width, height, color }: IconProps) => JSX.Element) | null;
}

interface BuildDataSeriesResponse {
  livestockDataSeries: Array<DataSerie>;
  productivityDataSeries: Array<DataSerie>;
  forestDataSeries: Array<DataSerie>;
  fertilizerDataSeries: Array<DataSerie>;
}

export function buildDataSeries(
  translate: TFunction<'translation', undefined>,
  fertilizerKeysData: Record<string, FertilizerKeyData>,
): BuildDataSeriesResponse {
  return {
    livestockDataSeries: getLivestockDataSeries(translate),
    productivityDataSeries: getProductivityDataSeries(translate),
    forestDataSeries: getForestDataSeries(translate),
    fertilizerDataSeries: getFertilizerDataSeries(
      translate,
      fertilizerKeysData,
    ),
  };
}

export function getLivestockDataSeries(
  translate: TFunction<'translation', undefined>,
) {
  return [
    {
      dataKey: 'cattleHeads.livestockUnit',
      name: translate('livestock'),
      fill: '#2563eb',
      customLabelDataKey: 'cattleHeads.livestockUnit',
      getCustomLabelIcon: () => LivestockIcon,
    },
  ];
}

export function getProductivityDataSeries(
  translate: TFunction<'translation', undefined>,
) {
  return [
    {
      dataKey: 'productivity.firstCrop.value',
      name: `${translate('productivity')} (${translate('firstCrop')})`,
      fill: '#ddac17',
      customLabelDataKey: 'productivity.firstCrop.crop',
      getCustomLabelIcon: getCropIcon,
    },
    {
      dataKey: 'productivity.secondCrop.value',
      name: `${translate('productivity')} (${translate('secondCrop')})`,
      fill: '#b38e1f',
      customLabelDataKey: 'productivity.secondCrop.crop',
      getCustomLabelIcon: getCropIcon,
    },
  ];
}

export function getForestDataSeries(
  translate: TFunction<'translation', undefined>,
) {
  return [
    {
      dataKey: 'forest.hasForest',
      name: translate('forest'),
      fill: '#00902f',
      customLabelDataKey: 'forest.hasForest',
      getCustomLabelIcon: () => ForestIcon,
    },
  ];
}

export function getFertilizerDataSeries(
  translate: TFunction<'translation', undefined>,
  fertilizerKeysData: Record<string, FertilizerKeyData>,
) {
  const fertilizerDataList = Object.values(fertilizerKeysData);

  const dataSeries = Object.values(fertilizerDataList)?.flatMap(
    (fertilizerData, fertilizerIndex) =>
      fertilizerData.cropKeysAvailable.sort().map((cropKey) => ({
        dataKey: `fertilizer.${fertilizerData.key}.${cropKey}.value`,
        name: `${fertilizerData.name} (${translate(cropKey)})`,
        fill: `#${generateSeriesColor(
          fertilizerIndex,
          fertilizerDataList.length,
          cropKey === 'secondCrop',
        )}`,
        customLabelDataKey: `fertilizer.${fertilizerData.key}.${cropKey}.crop`,
        getCustomLabelIcon: getCropIcon,
      })),
  );

  return dataSeries;
}
