/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import './GeoMap.scss';
import L from 'leaflet';
import { useEffect } from 'react';


function GeoMap({ geoJsonData } : {geoJsonData: string}) {
  const GeoJSONLayer = () => {
    const map = useMap();

    useEffect(() => {
      // Verifica se geoJsonData é válido antes de tentar adicioná-lo ao mapa
      if (geoJsonData != 'N') {        
        // Verifica se geoJsonData é uma string e tenta parseá-la, caso contrário, usa diretamente
        const data = typeof geoJsonData === 'string' ? JSON.parse(geoJsonData) : geoJsonData;

        const geoJsonLayer = L.geoJSON(data, {
          // Aqui você pode definir estilos ou eventos para o GeoJSON
          onEachFeature: (feature, layer) => {
            if (feature.properties && feature.properties.popupContent) {
              layer.bindPopup(feature.properties.popupContent);
            }
          }
        }).addTo(map);

        // Calcula os limites do GeoJSON e ajusta o zoom do mapa
        const bounds = geoJsonLayer.getBounds();
        if (bounds.isValid()) { // Verifica se os limites são válidos antes de aplicar o zoom
          map.fitBounds(bounds);
        }

        return () => {
          if (map.hasLayer(geoJsonLayer)) {
            map.removeLayer(geoJsonLayer);
          }
        };
      }else {
        map.setView([-14.2350, -51.9253], 4);
      }
    }, [geoJsonData, map]); // Adicione geoJsonData às dependências do useEffect

    return null;
  };


  
  return ( 
    <MapContainer
      className='container'
      center={[-14.2350, -51.9253]}
      minZoom={3}
      zoom={4}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
      {geoJsonData && <GeoJSONLayer /> }
    </MapContainer>
  );
}

export default GeoMap;