import { IconProps } from './Icon.types';

export function OthersIcon({
  width = '32',
  height = '32',
  color = 'rgb(0,0,0)',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 500 500"
      style={{ width: '100%', height: 'auto' }}
    >
      <g transform="matrix(1 0 0 1 250 250)" id="NQDW4uCCf5dT2uyxRht9i">
        <g>
          <g
            transform="matrix(1 0 0 1 -63.7003564631 0)"
            id="eSvyJJLpBrYAI0GagyAat"
          >
            <path
              stroke={color}
              strokeWidth="5"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(-23.6913390954, -23.6913390954)"
              d="M 23.69133 0 C 36.768950000000004 0 47.38266 10.61372 47.38266 23.69133 C 47.38266 36.768950000000004 36.76894 47.38266 23.69133 47.38266 C 10.613710000000001 47.38266 0 36.76894 0 23.69133 C 0 10.613710000000001 10.61372 0 23.69133 0 z"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(1 0 0 1 0.0000045477 -0.0000090954)"
            id="fqsG8F7Hj_QAI2W2dGAgj"
          >
            <path
              stroke={color}
              strokeWidth="5"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(-23.69133, -23.69133)"
              d="M 23.69133 0 C 36.768950000000004 0 47.38266 10.61372 47.38266 23.69133 C 47.38266 36.768950000000004 36.76894 47.38266 23.69133 47.38266 C 10.613710000000001 47.38266 0 36.76894 0 23.69133 C 0 10.613710000000001 10.61372 0 23.69133 0 z"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(1 0 0 1 63.7003655585 -0.0000090954)"
            id="iBQw2hJ5s16YLUy9JfG6z"
          >
            <path
              stroke={color}
              strokeWidth="5"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              vectorEffect="non-scaling-stroke"
              transform=" translate(-23.69133, -23.69133)"
              d="M 23.69133 0 C 36.768950000000004 0 47.38266 10.61372 47.38266 23.69133 C 47.38266 36.768950000000004 36.76894 47.38266 23.69133 47.38266 C 10.613710000000001 47.38266 0 36.76894 0 23.69133 C 0 10.613710000000001 10.61372 0 23.69133 0 z"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
