import { useEffect } from 'react';
import { ManageUsersService } from '../../services/ManageUsersService';
import { useManagableRolesStore } from './managableRoleStore';

export function useLoadManagableRoles() {  
  useEffect(() => {
    loadManagableRoles();
  }, []);
}

export async function loadManagableRoles() {
  const {
    loadManagableRolesData,
    setManagableRolesIsError,
    setManagableRolesIsLoading,
  } = useManagableRolesStore.getState();

  try {
    setManagableRolesIsLoading();
    
    loadManagableRolesData({
      managableRolesList: (await ManageUsersService.getRoles()).items,
    });   

  } catch (error) {
    setManagableRolesIsError();
  }
}
