import { TextField } from '@mui/material';
import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './UserSearchInput.scss';
import { changeSearchTerms } from '../../../../store/ManagableUsersStore/ManagableUserActions';

const CALL_API_INTERVAL_IN_MILISECONDS = 500;

export function UserSearchInput() {
  const [searchCurrentValue, setSearchCurrentValue] = useState<
    string | undefined
  >('');

  const { t: translate } = useTranslation();
  const timerId = useRef<NodeJS.Timeout>();

  function handleOnChangeInput(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    setSearchCurrentValue(event.target.value);

    clearTimeout(timerId.current);
    timerId.current = setTimeout(function () {
      changeSearchTerms(event.target.value);
    }, CALL_API_INTERVAL_IN_MILISECONDS);
  }

  return (
    <TextField
      variant="outlined"
      value={searchCurrentValue}
      onChange={handleOnChangeInput}
      placeholder={translate('user_mgt_search_placeholder')}
      className="user-mgmt-table-input"
    />
  );
}
