import { Alert, Box, Button, Snackbar, TextField } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AccountBanner from '../../../../components/AccountBanner';
import LoginNavigation from '../../../../components/LoginNavigation';
import { PublicRoutes } from '../../../../domains/AppRoutes';
import AccountService from '../../../../services/AccountService';
import '../ForgotPassword.scss';
import { NewPassViewProps } from '../ForgotPassword.types';

interface NewPassFormValues {
  password: string;
  newPassword: string;
}

function NewPassView(props: NewPassViewProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const service = AccountService;
  const [newPassViewError, setNewPassViewError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<NewPassFormValues>();

  function onSubmit(data: NewPassFormValues) {
    const { password } = data;
    const { newPassword } = data;
    const { email, resetToken } = props;
    if (password === newPassword) {
      service
        .changePassword({ data: { password, email }, resetToken })
        .then(() => {
          navigate(PublicRoutes.LOGIN);
        })
        .catch((error) => {
          setOpen(true);
          if (error.data && error.data.message) {
            setNewPassViewError(error.data.message);
          } else {
            setNewPassViewError('Ocorreu um erro');
          }
        });
    } else {
      setOpen(true);
      setNewPassViewError('Senhas devem ser iguais');
    }
  }

  const handleClose = () => {
    setOpen(false);
    setNewPassViewError('');
  };

  return (
    <Box className="ilpf-forgot-password-screen">
      <Box className="ilpf-forgot-password-content">
        <LoginNavigation />
        <Box className="ilpf-forgot-password-main-box">
          <Box className="ilpf-forgot-password-title">
            <h1>{t('new_pass_view_title')}</h1>
          </Box>
          <Box className="ilpf-forgot-password-text">
            <span>{t('new_pass_view_text')}</span>
          </Box>
          <Box className="ilpf-forgot-password-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="password">{t('new_pass_view_new_pass')}</label>
              <TextField
                id="password"
                type="password"
                {...register('password', { required: true })}
                error={!!errors.password}
                variant="outlined"
              />
              {errors.password && errors.password.type === 'required' && (
                <span className="error-message">
                  {t('error_required_new_pass')}
                </span>
              )}
              <label htmlFor="newPassword">{t('new_pass_view_confirm')}</label>
              <TextField
                id="newPassword"
                type="password"
                {...register('newPassword', { required: true })}
                error={!!errors.newPassword}
                variant="outlined"
              />
              {errors.newPassword && errors.newPassword.type === 'required' && (
                <span className="error-message">
                  {t('error_required_confirm')}
                </span>
              )}
              <Button
                type="submit"
                className="forgot-password-button"
                variant="contained"
              >
                {t('new_pass_view_button')}
              </Button>
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert severity="error" onClose={handleClose}>
                  {newPassViewError}
                </Alert>
              </Snackbar>
            </form>
          </Box>
        </Box>
      </Box>
      <AccountBanner />
    </Box>
  );
}

export default NewPassView;
