import { RawGlebeEvent } from '../../domains/GlebeEvent';
import ApiHelper from '../../helpers/ApiHelper';
import { BASE_URLS, URLS } from '../apiConstants';

interface GetGlebeEventsByGlebeIdParams {
  glebeId: string;
  page: string;
  itemsPerPage: string;
  order: string;
}

const GlebeEventService = {
  getGlebeEventsByGlebeId: ({
    glebeId,
    page,
    itemsPerPage,
    order,
  }: GetGlebeEventsByGlebeIdParams) =>
    ApiHelper.callApi<{ itens: Array<RawGlebeEvent> }>({
      baseUrl: BASE_URLS.WEB_API,
      headers: {},
      method: 'GET',
      url: `${URLS.GET_GLEBE_EVENT_LIST}/${glebeId}`,
      paramSearch: { page, itensPerPage: itemsPerPage, order },
    }),
};

export default GlebeEventService;
