function Terms() {
  return <div style={{ marginLeft: 16, marginRight: 16 }}>
    <h1 style={{ textAlign: 'center' }}>Política de Privacidade e Termo e Condições Gerais de Uso da aplicação digital</h1>
    <h3>POLÍTICA DE PRIVACIDADE</h3>

    <h4 style={{ textAlign: 'left' }}>SEÇÃO 1 - INFORMAÇÕES GERAIS</h4>
    <p>A presente Política de Privacidade contém informações sobre coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos usuários e de metadados da aplicação digital ‘ILPFdigital’ (doravante designado apenas plataforma, aplicação ou aplicativo) da Associação Rede ILPF, com a finalidade de demonstrar transparência quanto ao assunto e esclarecer a todos interessados sobre os tipos de dados que são coletados, os motivos da coleta e a forma como os usuários podem gerenciar as suas informações pessoais.</p>
    <p>Esta Política de Privacidade aplica-se a todos os usuários do aplicativo e integra os Termos e Condições Gerais de Uso do aplicativo, devidamente inscrita no CNPJ sob o nº 29.928.665/0001-41, situado em Quadra CRS 515, nº 78, Bloco B, sala 101 andar 1 parte A15, Asa sul, Brasília-DF, CEP: 70381-520, doravante nominada Associação Rede ILPF.</p>
    <p>O presente documento foi elaborado em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei 12.965/14) e o Regulamento da União Europeia (UE n. 2016/6790). Ainda, o documento poderá sofrer alterações em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.</p>

    <h4 style={{ textAlign: 'left', marginTop: 24 }}>SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO? </h4>
    <p>Os dados pessoais do usuário são recolhidos pela plataforma da seguinte forma: Quando o usuário cria uma conta/perfil: esses dados são básicos de identificação. A partir desses dados, podemos identificar o usuário, além de garantir maior segurança e otimização da experiência de uso da aplicação. Ficam cientes os usuários de que seu perfil na aplicação digital NÃO estará acessível aos demais usuários.</p>
    <p>Quando um usuário acessa o aplicativo, as informações sobre a sua interação e acesso à aplicação digital são coletadas para garantir e melhorar a experiência do usuário. A coleta dos dados é feita mediante cookies, persistentes ou de sessão. Por intermédio de terceiro: a aplicação digital recebe dados de terceiros, como o Google, quando um usuário faz login com o seu perfil de um desses sites. A utilização desses dados é autorizada previamente pelos usuários junto ao terceiro em questão.</p>

    <h4 style={{ textAlign: 'left', marginTop: 24 }}>SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO?</h4>
    <p>Os dados pessoais do usuário recolhidos são os seguintes:</p>
    <p>Dados para a criação da conta/perfil na aplicação: nome do produtor, e-mail, CPF ou CNPJ e telefone. Um ou mais códigos públicos do Cadastro Ambiental Rural (CAR) também é (são) necessário(s) para usufruir de todas as funcionalidades da aplicação.</p>
    <p>Dados para otimização da navegação: tempo e número de acessos e de interação com a aplicação, endereço de IP, localização do usuário, tempo de uso, marca e modelo do dispositivo, e outros possíveis de serem obtidos de dispositivos móveis ou de tablets, computadores e notebooks.</p>
    <p>Dados sensíveis: a aplicação digital poderá coletar os seguintes dados sensíveis do usuário: CPF, nome, CAR, os quais serão mantidos sob sigilo na plataforma ILPFdigital, e disponibilizados para terceiros somente mediante autorização expressa ou solicitação formal do usuário.</p>

    <h4 style={{ textAlign: 'left', marginTop: 24 }}>SEÇÃO 4 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO USUÁRIO?</h4>
    <p>Os dados pessoais do usuário coletados e armazenados pela aplicação digital têm por finalidade:</p>
    <p>Bem-estar do usuário: aprimorar o serviço oferecido, facilitar, agilizar e cumprir os compromissos estabelecidos entre o usuário e a Rede ILPF, melhorar a experiência dos usuários e fornecer funcionalidades que combinem os seus interesses e das associadas da Rede ILPF; entrar em contato com o usuário ou enviar notificações automáticas para comunicação em relação ao bom uso da aplicação.</p>
    <p>Melhorias da aplicação: compreender como o usuário utiliza os serviços da aplicação para aprimorar a oferta de funcionalidades e ampliar os benefícios mútuos entre os usuários e a Rede ILPF.</p>
    <p>Dados de cadastro: para permitir o acesso do usuário a conteúdos gerados pela aplicação digital (visualização de dados privados), exclusivos para usuários cadastrados.</p>
    <p>O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá mediante comunicação prévia ao usuário, de modo que os direitos e obrigações aqui previstos permanecem aplicáveis.</p>

    <h4 style={{ textAlign: 'left', marginTop: 24 }}>SEÇÃO 4 - TEMPO DE ARMAZENAMENTO DOS DADOS.</h4>
    <p>Os dados pessoais do usuário são armazenados pela aplicação digital durante o período necessário para a prestação do serviço ou o cumprimento das finalidades previstas no presente documento, conforme o disposto no inciso I do artigo 15 da Lei 13.709/18.</p>
    <p>Os dados podem ser removidos pelo usuário na própria aplicação digital (aba perfil), excetuando os casos em que a lei oferecer outro tratamento.</p>
    <p>Ainda, os dados pessoais dos usuários apenas podem ser conservados após o término de seu tratamento nas seguintes hipóteses previstas no artigo 16 da referida lei: </p>
    <p>I - cumprimento de obrigação legal ou regulatória pelo controlador;</p>
    <p>II - estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais; </p>
    <p>III - transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei;</p>
    <p>IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</p>
    <p>A exclusão da conta do usuário pode ser feita diretamente na aba perfil dentro do aplicativo, no botão ‘excluir conta’, a qualquer momento. </p>

    <h4 style={{ textAlign: 'left', marginTop: 24 }}>SEÇÃO 5 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</h4>
    <p>A aplicação se compromete a executar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados. </p>
    <p>A aplicação se compromete a utilizar os meios técnicos e administrativos necessários e suficientes para a proteção dos dados dos usuários. Nessas condições, a plataforma não se responsabiliza por violação de dados decorrentes de culpa exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiros, nos termos do art. 43, inciso III, da Lei n. 13.709/2018.</p>
    <p>A aplicação se compromete a comunicar o usuário e a Agência Nacional de Proteção de Dados (quando couber) em caso de alguma violação de segurança dos seus dados pessoais. </p>
    <p>Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais. No entanto, podemos divulgar suas informações pessoais nos casos de obrigação legal ou nos casos em que o usuário viole os presentes Termos de Serviço, além de casos determinados por decisão judicial. </p>

    <h4 style={{ textAlign: 'left', marginTop: 24 }}>SEÇÃO 6 - COMPARTILHAMENTO DOS DADOS</h4>
    <p>O compartilhamento de dados do usuário (dados pessoais e reportes sobre talhões e o manejo agropecuário) ocorre apenas com a Associação Rede ILPF e com a Embrapa (Empresa Brasileira de Pesquisa Agropecuária) para fins de pesquisa. Tais informações e ações não são compartilhadas publicamente com os outros usuários.</p>

    <h4 style={{ textAlign: 'left', marginTop: 24 }}>SEÇÃO 6 - OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A TERCEIROS?</h4>
    <p>Os dados pessoais poderão ser compartilhados com terceiros somente mediante autorização do usuário. Dados anonimizados (como histórico de uso de solo e manejo agropecuário), contudo, poderão ser compartilhados com terceiros, por exemplo, de forma agregada. </p>

    <h4 style={{ textAlign: 'left', marginTop: 24 }}>SEÇÃO 07 – COOKIES OU DADOS DE NAVEGAÇÃO E OPÇÕES DE GERENCIAMENTO E PERMISSÕES.</h4>
    <p>Os cookies referem-se a arquivos de texto enviados pela aplicação ao dispositivo eletrônico do usuário e neles ficam armazenados, com informações referentes à navegação no site. Tais informações são relacionadas aos dados de acesso como local e horário de acesso e são armazenadas pelo navegador do usuário para que o servidor da plataforma possa lê-las posteriormente a fim de personalizar os serviços da plataforma. </p>
    <p>O usuário da aplicação manifesta conhecer e aceitar que pode ser utilizado um sistema de coleta de dados de navegação mediante a utilização de cookies. </p>
    <p>O cookie persistente permanece no dispositivo eletrônico do usuário depois que o navegador é fechado e será usado pela aplicação em visitas subsequentes ao site. Os cookies persistentes podem ser removidos seguindo as instruções do seu navegador. Já o cookie de sessão é temporário e desaparece depois que o navegador é fechado. É possível redefinir seu navegador para recusar todos os cookies, porém alguns recursos da plataforma podem não funcionar corretamente se a capacidade de aceitar cookies estiver desabilitada.</p>
    <p>É possível fazer o gerenciamento de permissões de acesso pelo aplicativo, bem como recusá-las, porém alguns recursos podem não funcionar corretamente se algumas permissões estiverem desabilitadas. Na seção de gerenciamento de cookies em sistema web ou de permissões em sistema para dispositivos móveis (Android ou iOS), o usuário obterá informações a respeito das suas finalidades e a identificação de cookies ou permissões necessários e não necessários.</p>

    <h4 style={{ textAlign: 'left', marginTop: 24 }}>SEÇÃO 8 - CONSENTIMENTO</h4>
    <p>Ao utilizar os serviços e fornecer as informações pessoais na aplicação, o usuário está consentindo com a presente Política de Privacidade.</p>
    <p>O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus direitos de cancelar seu cadastro, acessar e atualizar seus dados pessoais e garante a veracidade das informações por ele disponibilizadas. </p>
    <p>O usuário tem o direito de retirar o seu consentimento a qualquer tempo, para tanto deve cancelar/excluir a sua conta na aba ‘perfil’ da aplicação digital.</p>

    <h4 style={{ textAlign: 'left', marginTop: 24 }}>SEÇÃO 9 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE </h4>
    <p>Reservamos o direito de modificar essa Política de Privacidade a qualquer momento, então, é recomendável que o usuário revise-a com frequência. </p>
    <p>As alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação na aplicação digital. Quando realizadas alterações nesta Política de Privacidade os usuários serão notificados. Ao utilizar o serviço ou fornecer informações pessoais após eventuais modificações, o usuário demonstra sua concordância com as novas normas. </p>

    <h4 style={{ textAlign: 'left', marginTop: 24 }}>SEÇÃO 10 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS </h4>
    <p>Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro.</p>
    <p>Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede da empresa Rede ILPF.</p>

    <h4>Termos e condições gerais de uso da aplicação digital</h4>

    <p>Os serviços da aplicação ‘ILPFdigital’ são fornecidos pela pessoa jurídica com a seguinte Razão Social/nome: Associação Rede ILPF, com nome fantasia Rede ILPF, inscrito no CNPJ sob o nº 29.928.665/0001-41, titular da propriedade intelectual sobre o aplicativo. </p>
    <p>1. Do objeto </p>
    <p>A aplicação visa licenciar o uso de seu aplicativo e sistema web, e demais ativos de propriedade intelectual, fornecendo ferramentas para auxiliar e dinamizar o dia a dia dos seus usuários. </p>
    <p>A aplicaçãol caracteriza-se pela prestação do seguinte serviço: gestão e coleta de dados de produtores rurais de sistemas ILPF. </p>
    <p>2. Da aceitação </p>
    <p>O presente Termo estabelece obrigações contratadas de livre e espontânea vontade, por tempo indeterminado, entre a aplicação digital e as pessoas físicas ou jurídicas, usuárias do aplicativo.</p>
    <p>Ao utilizar a aplicação digital o usuário aceita integralmente as presentes normas e compromete-se a observá-las, sob o risco de aplicação das penalidades cabíveis. </p>
    <p>A aceitação do presente instrumento é imprescindível para o acesso e para a utilização de quaisquer serviços fornecidos pela empresa. Caso não concorde com as disposições deste instrumento, o usuário não deve utilizá-los. </p>
    <p>3. Do acesso dos usuários </p>
    <p>Serão utilizadas todas as soluções técnicas à disposição do responsável pela aplicação digital para permitir o acesso ao serviço 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana. No entanto, a navegação na plataforma ou em alguma de suas páginas poderá ser interrompida, limitada ou suspensa para atualizações, modificações ou qualquer ação necessária ao seu bom funcionamento. </p>
    <p>4. Do cadastro </p>
    <p>O acesso às funcionalidades da aplicação digital exigirá a realização de um cadastro prévio do usuário.</p>
    <p>Ao se cadastrar o usuário deverá informar dados completos, recentes e válidos, sendo de sua exclusiva responsabilidade manter referidos dados atualizados, bem como o usuário se compromete com a veracidade dos dados fornecidos. </p>
    <p>O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à plataforma a terceiros, responsabilizando-se integralmente pelo uso que deles seja feito. </p>
    <p>Menores de 18 anos e aqueles que não possuírem plena capacidade civil deverão obter previamente o consentimento expresso de seus responsáveis legais para utilização da plataforma e dos serviços ou produtos, sendo de responsabilidade exclusiva destes últimos o eventual acesso por menores de idade e por aqueles que não possuem plena capacidade civil sem a prévia autorização. </p>
    <p>Mediante a realização do cadastro o usuário declara e garante expressamente ser plenamente capaz, podendo exercer e cadastrar as informações requeridas pela aplicação digital.</p>
    <p>O usuário deverá fornecer um endereço de e-mail válido, através do qual o site realizará todas as comunicações necessárias. </p>
    <p>Após a confirmação do cadastro, o usuário possuirá um login e uma senha pessoal, a qual assegura ao usuário o acesso individual à aplicação. Dessa forma, compete ao usuário exclusivamente a manutenção de referida senha de maneira confidencial e segura, evitando o acesso indevido às informações pessoais.</p>
    <p>Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário, que deverá informar prontamente a plataforma em caso de uso indevido. </p>
    <p>Não será permitido ceder, vender, alugar ou transferir, de qualquer forma, a conta, que é pessoal e intransferível. </p>
    <p>Caberá ao usuário assegurar que o seu equipamento seja compatível com as características técnicas que viabilizem a utilização da aplicação digital. O usuário poderá, a qualquer tempo, requerer o cancelamento de seu cadastro junto ao aplicativo. O seu descadastramento será realizado prontamente desde a sua solicitação (realizada na aba perfil do aplicativo para dispositivos móveis).</p>
    <p>O usuário, ao aceitar os Termos e Política de Privacidade, autoriza expressamente a aplicação digital a coletar, usar, armazenar, tratar, ceder ou utilizar as informações derivadas do uso dos serviços, do site e quaisquer plataformas, incluindo todas as informações preenchidas pelo usuário no momento em que realizar ou atualizar seu cadastro, além de outras expressamente descritas na Política de Privacidade, autorizadas pelo usuário. </p>
    <p>7. Do cancelamento </p>
    <p>O usuário poderá cancelar o cadastro na aplicação digital de acordo com os termos que forem definidos no momento de sua contratação. </p>
    <p>9. Do suporte </p>
    <p>Em caso de qualquer dúvida, sugestão ou problema com a utilização da aplicação digital, o usuário poderá entrar em contato com o suporte, através do email plataformaredeilpf@gmail.com. </p>
    <p>Esses serviços de atendimento ao usuário estarão disponíveis nos seguintes dias e horários: Segunda a sexta feira das 8:00 às 18:00. </p>
    <p>10. Das responsabilidades </p>
    <p>É de responsabilidade do usuário:</p>
    <p>a) defeitos ou vícios técnicos originados no próprio sistema do usuário; </p>
    <p>b) a correta utilização da aplicação digital, e das informações fornecidas;</p>
    <p>c) pelo cumprimento e respeito ao conjunto de regras disposto nesse Termo de Condições Geral de Uso, na respectiva Política de Privacidade e na legislação nacional e internacional pertinentes; </p>
    <p>d) pela proteção aos dados de acesso à sua conta/perfil (login e senha). </p>
    <p>É de responsabilidade da plataforma ILPF digital: </p>
    <p>a) indicar as características das informações requeridas na aplicação;</p>
    <p>b) os defeitos e vícios encontrados na aplicação desde que lhe tenha dado causa;</p>
    <p>c) informações que foram por ela divulgadas, sendo que os comentários ou informações divulgadas por usuários são de inteira responsabilidade dos mesmos;</p>
    <p>d) conteúdos ou atividades ilícitas praticadas através da sua plataforma. </p>
    <p>A plataforma não se responsabiliza por links externos contidos em seu sistema que possam redirecionar o usuário a ambiente externo à sua rede. </p>
    <p>Não poderão ser incluídos links externos ou páginas que sirvam para fins comerciais ou publicitários ou quaisquer informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias, ofensivas ou de qualquer forma vedadas pela legislação nacional. </p>
    <p>11. Dos direitos autorais </p>
    <p>O presente Termo de Uso concede aos usuários uma licença não exclusiva, não transferível e não sublicenciável, para acessar e fazer uso da aplicação digital. </p>
    <p>A estrutura do site ou aplicativo, as marcas, logotipos, nomes comerciais, layouts, gráficos e design de interface, imagens, ilustrações, fotografias, apresentações, vídeos, conteúdos escritos e de som e áudio, programas de computador, banco de dados, arquivos de transmissão e quaisquer outras informações e direitos de propriedade intelectual da razão social Associação Rede ILPF, com nome fantasia Rede ILPF, observados os termos da Lei da Propriedade Industrial (Lei nº 9.279/96), Lei de Direitos Autorais (Lei nº 9.610/98) e Lei do Software (Lei nº 9.609/98), estão devidamente reservados. </p>
    <p>Este Termo de Uso não cede ou transfere ao usuário qualquer direito, de modo que o acesso não gera qualquer direito de propriedade intelectual ao usuário, exceto pela licença limitada ora concedida. </p>
    <p>O uso da plataforma pelo usuário é pessoal, individual e intransferível, sendo vedado qualquer uso não autorizado, comercial ou não-comercial. Tais usos consistirão em violação dos direitos de propriedade intelectual da razão social Associação Rede ILPF, puníveis nos termos da legislação aplicável. </p>
    <p>12. Das sanções </p>
    <p>Sem prejuízo das demais medidas legais cabíveis, a razão social Associação Rede ILPF poderá, a qualquer momento, advertir, suspender ou cancelar a conta do usuário: </p>
    <p>a) que violar qualquer dispositivo do presente Termo; </p>
    <p>b) que descumprir os seus deveres de usuário;</p>
    <p>c) que demonstrar qualquer comportamento fraudulento, doloso ou que ofenda a ou prejudique terceiros. </p>
    <p>13. Da rescisão </p>
    <p>A não observância das obrigações pactuadas neste Termo de Uso ou na legislação aplicável poderá, sem prévio aviso, ensejar a imediata rescisão unilateral por parte da razão social Associação Rede ILPF e o bloqueio de todos os serviços prestados ao usuário. </p>
    <p>14. Das alterações </p>
    <p>Os itens descritos no presente instrumento poderão sofrer alterações, unilateralmente e a qualquer tempo, por parte da Associação Rede ILPF, para adequar ou modificar os serviços, bem como para atender novas exigências legais. As alterações serão veiculadas pelo aplicativo e o usuário poderá optar por aceitar o novo conteúdo ou cancelar seu cadastro.</p>
    <p>A aplicação pode, a qualquer tempo, unilateralmente e sem qualquer aviso prévio: deixar de ser fornecida, ser alterada em suas características, bem como ser restringida para o uso ou acesso. </p>
    <p>15. Da política de privacidade </p>
    <p>Além do presente Termo, o usuário deverá consentir com as disposições contidas na respectiva Política de Privacidade a ser apresentada a todos os interessados dentro da interface da plataforma. </p>
    <p>16. Do foro </p>
    <p>Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro. </p>
    <p>Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede da Associação Rede ILPF.</p>
  </div>;
}

export default Terms;
