import { jwtDecode } from 'jwt-decode';
import { User } from '../domains/User';
import { USER_DATA_STORAGE_KEY } from '../domains/UserDataKey';
import { UserRoleLabel } from '../domains/UserRole';

export function setStoredUserData(userData: User) {
  localStorage.setItem(USER_DATA_STORAGE_KEY, JSON.stringify(userData));
}

export function removeStoredUserData() {
  localStorage.removeItem(USER_DATA_STORAGE_KEY);
}

export function getStoredUserData(): User | null {
  const userData = localStorage.getItem(USER_DATA_STORAGE_KEY);

  if (userData) {
    return JSON.parse(userData);
  }

  return null;
}

export function getIsUserAuthenticated() {
  const authenticationToken = getStoredUserData()?.accessToken;

  if (authenticationToken) {
    const decodedToken = jwtDecode(authenticationToken);

    if (decodedToken.exp) {
      const expirationTimeInMiliseconds = decodedToken.exp * 1000;

      const currentTimeInMiliseconds = new Date().getTime();
      const isTokenValid =
        currentTimeInMiliseconds < expirationTimeInMiliseconds;

      return isTokenValid;
    }
  }

  return false;
}

export function getIsUserAdmin() {
    const userRoles = getStoredUserData()?.userRoles;
    const isUserAdmin = userRoles?.some(
      (role) => role.name === UserRoleLabel.ADMIN,
    );
    console.log('isUserAdmin', isUserAdmin);
    return isUserAdmin || false;
}
