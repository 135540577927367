interface CustomAxisTickProps {
  x: number;
  y: number;
  payload: {
    value: string;
  };
}

export function CustomAxisTick({ x, y, payload }: CustomAxisTickProps) {
  const MAX_WIDTH = 150;
  const FIRST_LINE_SEPARATOR = ' *** ';
  const MAX_LINES = 3;

  const text = payload.value;
  let remainingText = text;
  let lines = [];

  if (text.includes(FIRST_LINE_SEPARATOR)) {
    const firstLine = text.split(' *** ')[0];
    remainingText = text.split(' *** ')[1];
    lines.push(firstLine);
  }

  const words = remainingText.split(' ');
  let line = '';

  words.forEach((word: string) => {
    const newLine = `${line} ${word}`.trim();
    const width = calculateTextWidth({
      text: newLine,
      font: 'Roboto',
      fontSize: '12',
    });

    if (width < MAX_WIDTH) {
      line = newLine;
    } else {
      lines.push(line);
      line = word;
    }
  });

  lines.push(line);

  if (lines.length > MAX_LINES) {
    lines = lines.slice(0, MAX_LINES);
    lines[MAX_LINES - 1] += ' ...';
  }

  const lineHeight = 16;

  return (
    <g transform={`translate(${x},${y})`}>
      {lines.map((line, index) => (
        <text
          key={index}
          x={0}
          y={index * lineHeight}
          dy={lineHeight}
          textAnchor="middle"
          fill="#666"
          fontSize={12}
        >
          {line}
        </text>
      ))}
    </g>
  );
}

export function calculateTextWidth({
  text,
  font,
  fontSize,
}: {
  text: string;
  font: string;
  fontSize: string;
}) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  ctx.font = `${fontSize}px ${font}`;

  return ctx.measureText(text).width;
}
