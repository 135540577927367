import ApiHelper from '../../helpers/ApiHelper';
import { BASE_URLS, URLS } from '../apiConstants';
import {
  DeleteUserParams,
  GetUserListParams,
  GetUserListResponse,
  GetUserRolesListResponse,
  UpdateUserParams,
} from './ManageUsersService.types';

export const ManageUsersService = {
  getUserList: ({ page, itemsPerPage, searchTerms }: GetUserListParams) =>
    ApiHelper.callApi<GetUserListResponse>({
      baseUrl: BASE_URLS.WEB_API,
      headers: {},
      method: 'GET',
      url: `${URLS.GET_USER_LIST}`,
      paramSearch: { page, itemsPerPage, searchTerms },
    }),
  updateUser: ({ userId, userData }: UpdateUserParams) =>
    ApiHelper.callApi<void>({
      baseUrl: BASE_URLS.WEB_API,
      headers: {},
      method: 'PUT',
      url: `${URLS.GET_USER_LIST}/${userId}`,
      data: userData,
    }),
  deleteUser: ({ userId }: DeleteUserParams) =>
    ApiHelper.callApi<void>({
      baseUrl: BASE_URLS.WEB_API,
      headers: {},
      method: 'DELETE',
      url: `${URLS.GET_USER_LIST}/${userId}`,
    }),
  getRoles: () =>
    ApiHelper.callApi<GetUserRolesListResponse>({
      baseUrl: BASE_URLS.WEB_API,
      headers: {},
      method: 'GET',
      url: `${URLS.GET_USER_ROLES}`,
    }),
  updateUserRoles: ({ userId, userData }: UpdateUserParams) =>
    ApiHelper.callApi<void>({
      baseUrl: BASE_URLS.WEB_API,
      headers: {},
      method: 'PUT',
      url: `${URLS.UPDATE_ROLES}/${userId}`,
      data: userData,
    }),
};
