/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DataSerie } from '../../utils/buildDataSeries';
import './BarChart.scss';
import { CustomAxisTick } from './components/CustomAxisTick';
import { CustomLabel } from './components/CustomLabel';
import { CustomTooltip } from './components/CustomTooltip';

interface BarChartComponentProps {
  title: string;
  data: Array<Record<string, any>>;
  dataSeries: Array<DataSerie>;
  getAdditionalInfoKey: (dataKey: string) => Array<string>;
  yAxisLabel?: string;
  unavailableDataMessage?: string;
  id: string;
}

function BarChartComponent({
  title,
  data,
  dataSeries,
  getAdditionalInfoKey,
  yAxisLabel,
  unavailableDataMessage,
  id,
}: BarChartComponentProps) {
  const { containerMinWidth, barSize } = calculateChartMeasures(
    data.length,
    dataSeries.length,
  );

  return (
    <div
      id={id}
      className="bar-chart-container"
      style={{ minWidth: containerMinWidth }}
    >
      <span className="chart-title">{title}</span>
      <div className="chart-wrapper">
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 30,
              right: 30,
            }}
          >
            <Tooltip
              content={
                <CustomTooltip
                  getAdditionalInfoKey={getAdditionalInfoKey}
                  unavailableDataMessage={unavailableDataMessage}
                  parentId={id}
                />
              }
            />
            {dataSeries.map((dataSerie) => (
              <Bar
                key={dataSerie.dataKey}
                dataKey={dataSerie.dataKey}
                name={dataSerie.name}
                fill={dataSerie.fill}
                barSize={barSize}
              >
                {dataSerie.customLabelDataKey &&
                  dataSerie.getCustomLabelIcon && (
                    <LabelList
                      dataKey={dataSerie.customLabelDataKey}
                      content={
                        <CustomLabel
                          getCustomLabelIcon={dataSerie.getCustomLabelIcon}
                          fill={dataSerie.fill}
                        />
                      }
                    />
                  )}
              </Bar>
            ))}
            <CartesianGrid strokeDasharray="0 10" />
            <XAxis
              interval={0}
              dataKey="name"
              height={45}
              tick={CustomAxisTick}
            />
            <YAxis
              padding={{ top: 15 }}
              label={{
                value: yAxisLabel,
                angle: -90,
                position: 'insideLeft',
              }}
            />
            <Legend wrapperStyle={{ lineHeight: '40px', paddingTop: '20px' }} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

function calculateChartMeasures(dataNumber: number, barNumber: number) {
  const MIN_TOTAL_BAR_SIZE = 110;
  const MIN_BAR_SIZE = 60;
  const BAR_MIN_GAP = 60;

  const barSize =
    barNumber === 0
      ? MIN_BAR_SIZE
      : Math.max(MIN_BAR_SIZE, MIN_TOTAL_BAR_SIZE / barNumber);

  const containerMinWidth =
    dataNumber * barNumber * barSize + dataNumber * BAR_MIN_GAP;

  return { barSize, containerMinWidth };
}

export default BarChartComponent;
