// Base Url
export const BASE_URLS = {
  WEB_API: process.env.REACT_APP_WEB_API || 'http://localhost:3000/',
  WEB_API_PUBLIC: process.env.REACT_APP_PDRI_API_PUBLIC_URL || 'http://localhost:3000/',
};

// Url
export const URLS = {
  LOGIN: 'auth/login',
  CREATE_USER: 'users',
  RESET_PASSWORD: 'auth/password-reset',
  GENERATE_TOKEN: 'auth/password-reset/generate-token',
  CHANGE_PASSWORD: 'auth/password-reset/change-password',
  LOGIN_GOOGLE: 'auth/login-google',
  GET_USER_CARS: 'users/car/list',
  GET_FARMS: 'farms/list',
  GET_GLEBE_LIST: 'glebes/list',
  GET_GLEBE_EVENT_LIST: 'glebeevents/list',
  GET_USER_LIST: 'users',
  UPDATE_ROLES: 'users/roles',
  GET_USER_ROLES: 'users/roles/list'
};
