import { IconProps } from './Icon.types';

export function CottonIcon({
  width = '32',
  height = '32',
  color = 'rgb(0,0,0)',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 500 500"
      style={{ width: '100%', height: 'auto' }}
    >
      <g transform="matrix(1 0 0 1 250 250)" id="fzahK2v5EH87vJo4a7E5r">
        <g>
          <g
            transform="matrix(1 0 0 1 0 -11.7262916765)"
            id="Q0gdOuCroVhoHw6QOCz7C"
          >
            <path
              stroke={color}
              strokeWidth="5"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              transform=" translate(-0.0000044262, -0.0000036786)"
              d="M -84.10075 87.02665 C -108.9383 78.36239 -137.81916 29.842530000000004 -93.92024 -5.392119999999991 C -118.75779 -52.17911999999999 -62.15129 -119.76034999999999 -13.053810000000013 -68.93002999999999 C 28.53463999999999 -108.20800999999999 79.36495999999998 -71.24049999999998 74.16640999999998 -23.298259999999985 C 109.40106999999998 -15.211619999999986 134.81623 33.885860000000015 100.15918999999998 69.69813000000002"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(1 0 0 1 -18.0786009466 -46.2833743018)"
            id="uV8y2r101B8sF0kcZyLic"
          >
            <path
              stroke={color}
              strokeWidth="5"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              transform=" translate(-1.247e-7, 0.000005)"
              d="M -1.59433 -19.60125 C -1.59433 -19.60125 -6.83052 4.005579999999998 2.98897 19.601239999999997"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(1 0 0 1 -46.0681032584 -7.9881258369)"
            id="cH5PPvSSbiWfzVP4VAhdq"
          >
            <path
              stroke={color}
              strokeWidth="5"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              transform=" translate(0, -0.0000019292)"
              d="M -26.45199 -0.44716 C -7.390619999999998 11.35154 14.028500000000005 4.3579099999999995 26.45199 -5.7268"
              strokeLinecap="round"
            />
          </g>
          <g
            transform="matrix(1 0 0 1 2.1052003773 63.6605635677)"
            id="H57fILEF5dcYK4BhlOL6t"
          >
            <path
              stroke={color}
              strokeWidth="5"
              strokeDashoffset="0"
              strokeDasharray="none"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              fill="#FFFFFF"
              fillOpacity="0"
              fillRule="nonzero"
              opacity="1"
              transform=" translate(0.00001, -0.0000075005)"
              d="M -32.93428 23.86799 C -42.05173 25.85719 -79.94988000000001 21.833849999999998 -92.23058 -8.74259 C -77.33290000000001 -13.55194 -42.286550000000005 0.7082200000000007 -33.73969 4.508990000000001 C -44.230720000000005 -8.57104 -43.82802 -25.53457 -41.57238 -32.80274 C -35.42108 -32.21024 -7.569900000000004 -14.783180000000002 -1.4405000000000001 2.395690000000002 C 8.39477 -24.113539999999997 28.3208 -35.81026 35.83959 -35.05838 C 37.19492 -29.323169999999998 40.22677 -9.1234 27.203920000000004 4.508989999999997 C 56.52723 -4.932090000000002 83.84552 -3.5585100000000036 92.23056 -0.9731600000000027 C 86.58173 2.881139999999997 69.31282999999999 23.978779999999997 26.027429999999995 22.867999999999995 C 17.208049999999993 22.080839999999995 17.283479999999997 26.982659999999996 10.015309999999996 31.681299999999993 C 2.7471399999999964 36.37993999999999 -9.178310000000003 35.39932999999999 -16.091220000000003 33.61330999999999 C -23.004130000000004 31.82728999999999 -23.816840000000003 21.87879999999999 -32.93430000000001 23.86799999999999 z"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
