import { IconProps } from './Icon.types';

export function SorghumIcon({
  width = '32',
  height = '32',
  color = 'rgb(0,0,0)',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 500 500"
      style={{ width: '100%', height: 'auto' }}
    >
      <g
        transform="matrix(1 0 0 1 224.2250399046 227.8965274101)"
        id="ONzUnOcYcfjxhHi2sUXS2"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 24.27772 35.62466 C 22.75957 38.85241 11.32317 38.579209999999996 10.641359999999999 32.67011 C 5.414089999999999 35.85193 -6.63137 31.53375 -5.722280000000001 25.397380000000002 C -13.449550000000002 28.351930000000003 -22.08592 18.351930000000003 -16.4041 10.851930000000001 C -26.858649999999997 6.761020000000001 -26.858649999999997 -3.4662499999999987 -21.17683 -6.420799999999998 C -34.13138 -9.602619999999998 -30.5133 -24.106279999999998 -21.17683 -25.51171 C -28.9041 -34.60262 -14.13138 -43.01171 -9.58592 -33.46626 C -3.8516499999999994 -36.57815 1.6378599999999999 -33.2215 2.0923999999999996 -27.766959999999997 C 7.37212 -31.368359999999996 11.1396 -27.487239999999996 12.1973 -24.812409999999996 C 13.25499 -22.137579999999996 10.85141 -17.798689999999997 11.936490000000001 -15.190179999999996 C 13.02157 -12.581669999999995 13.693460000000002 -11.923279999999997 17.82046 -10.995579999999997 C 13.35603 -7.049039999999996 16.81514 -2.8099199999999964 18.48075 -1.7965199999999975 C 19.94118 -0.9079499999999975 20.44788 0.20316000000000245 24.958080000000002 -0.11403999999999748 C 25.59174 -0.15859999999999747 28.745440000000002 4.856580000000003 29.504990000000003 8.316960000000002 C 30.264550000000003 11.777340000000002 25.641350000000003 13.806470000000001 27.914080000000002 17.67011 C 30.18681 21.53375 24.606070000000003 22.46577 23.762400000000003 26.66158 C 22.918730000000004 30.85738 25.795870000000004 32.39691 24.277720000000002 35.62466 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 254.1190867148 181.4962733712)"
        id="1ip2i58JsMfLBda0YG_jE"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -20.2089 17.63976 C -22.72638 13.443959999999999 -20.68553 7.06542 -18.02818 6.366119999999999 C -24.60161 3.988499999999999 -23.90231 -6.501010000000001 -17.46874 -8.459050000000001 C -21.52468 -11.675830000000001 -21.67003 -23.81709 -12.01419 -24.54297 C -14.39181 -30.83668 -14.777429999999999 -36.27933 -6.460999999999999 -38.50173 C -8.53961 -44.236000000000004 -7.15879 -49.53342 0.564750000000001 -52.23528 C 8.28829 -54.93714 11.364270000000001 -47.51086 8.54526 -41.326190000000004 C 17.83054 -41.611360000000005 16.93687 -30.556960000000004 11.901900000000001 -27.759760000000004 C 18.47533 -25.102420000000002 22.25155 -16.291230000000006 13.58022 -10.277240000000003 C 26.02777 -4.822690000000002 21.83197 5.247239999999998 13.58022 6.366119999999999 C 26.02777 10.981499999999999 20.99281 18.25423 16.797 21.610879999999998 C 26.58721 25.387099999999997 22.50213 35.689569999999996 15.957840000000001 36.855639999999994 C 17.432760000000002 44.751349999999995 14.600200000000001 48.762739999999994 7.31418 46.585939999999994 C 5.64864 53.228359999999995 1.0722500000000004 53.592009999999995 -1.0256499999999988 52.05353999999999"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 281.3301768998 239.6985287194)"
        id="pjxRuifc2vLcPTtYoZujp"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -10.93395 -20.60837 C -8.83605 -22.28669 -5.370089999999999 -23.33612 -1.9008500000000002 -19.77702 C 0.49431 -25.2802 -0.1170500000000001 -31.80732 11.38586 -29.14765 C 12.12187 -31.7628 21.54565 -37.49939 25.65159 -27.46933 C 33.9932 -28.954639999999998 36.61616 -17.180639999999997 29.56767 -15.58122 C 37.52874 -9.49614 30.16149 -1.1045300000000005 23.27396 -3.693110000000001 C 27.14005 -0.6592100000000007 26.630599999999998 7.355839999999999 17.95927 4.9782199999999985 C 19.54225 11.902459999999998 17.12011 15.327869999999999 8.58864 14.48871 C 11.73666 22.38965 5.51172 28.05514 -2.6001700000000003 24.97822 C -1.3945800000000004 34.97158 -14.0687 34.628569999999996 -16.02674 28.33486 C -20.222540000000002 36.72647 -35.16577 33.386179999999996 -33.50926 24.418779999999998"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 220.4646765781 222.2416597514)"
        id="NmAfV_86vR2lV0aYhTCHY"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 2.00728 -5.79127 C 5.22406 -2.85421 5.28237 0.3281900000000002 3.65952 3.1597800000000005 C 2.03668 5.99137 -1.7689499999999998 6.51642 -4.706009999999999 4.838100000000001"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7662814326 0 0 0.7662814326 232.7083211675 239.3030366762)"
        id="kcBlnKEaKkHdxlAKelT0s"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 4.30377 -6.26729 C 7.3806899999999995 -0.9526000000000003 7.80027 4.493759999999999 2.90517 5.892359999999999 C -1.9899300000000002 7.290959999999999 -5.90602 4.633619999999999 -6.605320000000001 0.29794999999999927"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7799159939 0 0 0.7799159939 293.1200787482 225.2348858953)"
        id="TyrAHI-UCETLBTGo8Tn2i"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -6.12252 -3.9309 C -6.262379999999999 0.9642000000000004 -3.3253199999999996 3.63709 -0.24838999999999967 3.9168100000000003 C 2.82853 4.19653 6.74462 0.2649100000000004 6.04532 -3.9309"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7264177902 0 0 0.7264177902 287.8412807869 239.757082887)"
        id="By8nY2LILLZJ6FDhno5ab"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -6.08392 -3.08044 C -5.66434 2.23425 -2.53278 2.93355 0.5441399999999996 3.0734100000000004 C 3.6210599999999995 3.2132700000000005 5.94405 1.2552300000000005 6.0839099999999995 -1.96156"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.7241342413 0 0 0.7241342413 273.8785388937 252.7828254335)"
        id="LQJWTLm3nCO7Jn5_PXnwA"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -5.06488 -3.84297 C -6.46348 0.35283 -3.2466999999999997 3.027 -0.44949999999999957 3.7263 C 2.3477000000000006 4.4256 5.903650000000001 1.9081200000000003 5.34421 -1.8681099999999997"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 260.3013983081 238.4550041328)"
        id="7gJ294R3szNpQSJXIZg8o"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -8.34538 10.44294 C -5.1286000000000005 8.62476 -4.487500000000001 8.93431 0.77196 10.44294 C 1.1278 3.05051 2.75098 0.012869999999999493 8.34539 -0.5465699999999991 C 4.84889 -4.742369999999999 5.94679 -9.46392 7.34539 -10.442939999999998"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.8388295118 0 0 0.8388295118 252.1686022136 169.9273614161)"
        id="9iOVP6GLZHFjm0YMrrq27"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -1.21654 -5.72898 C -4.8529 -4.19052 -5.33047 0.43032000000000004 -3.93187 2.3883600000000005 C -2.53327 4.346400000000001 -0.6570999999999998 7.13815 4.657579999999999 4.900390000000001"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 260.4058251327 192.6506739247)"
        id="242cmk3Veyfwdf54sQlGN"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -7.24096 2.26126 C -2.6255800000000002 6.03748 0.2924599999999993 4.40135 1.962979999999999 2.26126 C 3.633489999999999 0.12117000000000022 3.5147299999999992 -3.9613500000000004 7.240959999999999 -4.52079"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(0.8167064533 0 0 0.8167064533 255.8874786773 213.00973257)"
        id="v8YDD08Eq9MC9SThENcEs"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -5.38759 -2.51748 C -4.548430000000001 0.1398600000000001 -2.1464000000000003 2.5174900000000004 0.3710800000000001 2.5174900000000004 C 2.88856 2.5174900000000004 5.24773 0.27973000000000026 5.38759 -2.51748"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 240.739078169 185.6601823825)"
        id="8BdNvoZJCmRZJpNb2LyxO"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -4.74836 2.65734 C -1.2518599999999998 2.65734 3.34976 1.81818 4.74836 -2.6573499999999997"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 241.6422656642 202.9992868265)"
        id="kv8uF0efSF03l76kTtVQ9"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 4.84513 -1.70285 C 2.88709 0.9544900000000001 2.14787 1.563 -4.84514 1.70286"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 224.4938572414 203.962629935)"
        id="BWhDWLZfNBpdgo22A0zdi"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 1.9871 -4.37587 C 2.01368 -0.02691999999999961 1.22903 1.2989600000000001 -1.98775 4.3758799999999995"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 271.7578954231 240.6059865783)"
        id="NslC2DFEwW_sfNKWHU5Mn"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -3.61111 -2.69755 C 0.025249999999999773 -0.4597899999999999 0.9537699999999996 -1.3583900000000002 3.61111 2.69755"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 206.940838213 220.8674473398)"
        id="rUH7w6XTz2ljIFbvvy3Uo"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -3.78287 0.80167 C -1.12553 0.80167 2.10454 0.17735 3.78287 -0.80167"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 212.7306991834 236.8026890135)"
        id="TpMq01A66Qb1XbWjUfCuz"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -4.96503 1.91174 C -0.90909 0.653 0.20980000000000043 2.00435 4.965040000000001 -1.91173"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 236.1615224855 256.9399026622)"
        id="knaC2fyH2aPIgQWwqf_Gh"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -1.90941 4.40559 C 0.8877900000000001 1.3286700000000002 2.2544999999999993 -1.0489600000000001 1.83492 -4.4056"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 220.3338460365 250.3454970678)"
        id="CK41Ef8-0RcQ8t9mmCjtk"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -1.77797 3.14685 C -0.5192300000000001 -0.06992999999999983 -0.18006999999999995 -2.02798 1.77797 -3.1468599999999998"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 261.4929369456 153.8017350769)"
        id="nP2JwQYL0TFN0EJLC-G01"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 4.47552 0.35542 C 1.2298300000000002 1.3821100000000002 -1.3986099999999997 1.8095 -4.47553 -1.12756"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 182.351328554 305.3925616449)"
        id="IdeY4vEzGM5ib_Xn7LVCZ"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -21.63811 21.12776 C -21.498250000000002 14.134749999999999 -17.58217 -10.62049 -6.812940000000001 -17.33378 C 3.956289999999999 -24.04707 18.28147 -19.95098 21.638109999999998 -19.25168 C 5.973769999999998 -13.79713 -15.484270000000002 12.17671 -21.638110000000005 21.127760000000002 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 217.034074348 322.7342214582)"
        id="xHNqvdtrLUAydygNB5wVU"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 35.40128 36.09379 C 30.50618 16.37351 27.955370000000002 1.4084799999999973 20.68264 -10.199919999999999 C 13.40991 -21.80831 -2.2544199999999996 -35.51461 -10.64603 -36.07405 C -19.03764 -36.63349 -31.90477 -25.16496 -35.40127 -20.12999 C -18.058609999999998 -21.38873 -2.894279999999995 -6.983139999999999 1.021810000000002 -3.486629999999998 C 4.937890000000002 0.009870000000002044 25.051640000000003 22.94694 35.40129 36.09379 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-1 0 0 1 323.2340387207 306.6410773033)"
        id="Dof3ZVZ3V9pUtoICW98y3"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 2.708e-7)"
          d="M -21.63811 21.12776 C -21.498250000000002 14.134749999999999 -17.58217 -10.62049 -6.812940000000001 -17.33378 C 3.956289999999999 -24.04707 18.28147 -19.95098 21.638109999999998 -19.25168 C 5.973769999999998 -13.79713 -15.484270000000002 12.17671 -21.638110000000005 21.127760000000002 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-1 0 0 1 287.8412807869 323.6306208462)"
        id="GvTUTwdjRz2wABitrh3-f"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.00001, 0.00000699)"
          d="M 35.40128 36.09379 C 30.50618 16.37351 27.955370000000002 1.4084799999999973 20.68264 -10.199919999999999 C 13.40991 -21.80831 -2.2544199999999996 -35.51461 -10.64603 -36.07405 C -19.03764 -36.63349 -31.90477 -25.16496 -35.40127 -20.12999 C -18.058609999999998 -21.38873 -2.894279999999995 -6.983139999999999 1.021810000000002 -3.486629999999998 C 4.937890000000002 0.009870000000002044 25.051640000000003 22.94694 35.40129 36.09379 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1.0320254597 0 0 1.0320254597 251.9560135167 315.1666078028)"
        id="40v4tiWsL5CjE_mu5jqfY"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 0 -41.90156 L 0 41.90156999999999"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}
