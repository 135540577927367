/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import './DataTable.scss';

function DataTable({ data }: { data: any}) {
  if (!data || data.length === 0) {
    return <div className="no-data">Nenhuma informação no momento</div>;
  }
  
  return (
    <>
      <TableContainer component={Paper} className="table-container">
      <Table className="MuiTable-root">
        <TableHead className="MuiTableHead-root">
          <TableRow>
            <TableCell className="MuiTableCell-head">Sistema de produção</TableCell>
            <TableCell className="MuiTableCell-head">Área (ha)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow className="MuiTableRow-root">
              <TableCell component="th" scope="row">
              Soja / Cereais
              </TableCell>
              <TableCell className="MuiTableCell-body">{!isNaN(data.pixel_1) ? data.pixel_1 : 0}</TableCell>
            </TableRow>
            <TableRow className="MuiTableRow-root">
              <TableCell component="th" scope="row">
              ILP
              </TableCell>
              <TableCell className="MuiTableCell-body">{!isNaN(data.pixel_2) ? data.pixel_2 : 0}</TableCell>
            </TableRow>
            <TableRow className="MuiTableRow-root">
              <TableCell component="th" scope="row">
              Soja / Algodão
              </TableCell>
              <TableCell className="MuiTableCell-body">{!isNaN(data.pixel_3) ? data.pixel_3 : 0}</TableCell>     
            </TableRow>      
        </TableBody>
      </Table>
    </TableContainer>
    <div className="label-img-container">
      <label>Fonte </label>
      <img src={`${process.env.PUBLIC_URL}/resources/images/partnerGeoABC.png`} alt="Fonte" title="Fonte" />
    </div>
  </>
  );
}

export default DataTable;