import { create } from 'zustand';
import { FarmState, FarmStore } from './FarmStore.types';

const initialState: FarmState = {
  farms: [],
  farmsIsLoading: false,
  farmsIsError: false,
  selectedFarmDisplayId: null,
};

export const useFarmStore = create<FarmStore>()((set) => ({
  ...initialState,
  setSelectedFarmDisplayId: (farmDisplayId: string) =>
    set((state: FarmState) => ({
      ...state,
      selectedFarmDisplayId: farmDisplayId,
    })),
  reset: () => {
    set(initialState);
  },
}));
