import { IconProps } from './Icon.types';

export function LivestockIcon({
  width = '32',
  height = '32',
  color = 'rgb(0,0,0)',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 500 500"
      style={{ width: '100%', height: 'auto' }}
    >
      <g
        transform="matrix(1 0 0 1 250.6319558584 270.5311624187)"
        id="YRXjB6sMOcwIKuQUJhQhn"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -27.60213 72.65043 C -33.732659999999996 69.90485 -42.87004 63.62516 -45.577619999999996 55.95368 C -48.285199999999996 48.282199999999996 -45.12636 32.03671 -45.577619999999996 25.719019999999997 C -46.028879999999994 19.401329999999998 -63.176899999999996 -23.919970000000003 -63.176899999999996 -32.04271 C -63.176899999999996 -40.16545 -37.454879999999996 -81.23044 -33.39350999999999 -84.84054 C -29.332139999999992 -88.45065000000001 27.978330000000007 -89.35318000000001 33.393490000000014 -84.84054 C 38.808650000000014 -80.3279 63.17688000000001 -39.71419 63.17688000000001 -34.299020000000006 C 63.17688000000001 -28.883860000000006 45.57760000000001 20.303869999999996 45.57760000000001 25.719029999999997 C 45.57760000000001 31.134189999999997 48.73644000000001 49.184729999999995 45.57760000000001 55.953689999999995 C 42.41876000000001 62.72264 31.68254000000001 68.39931 26.17327000000001 72.65044 C 20.66400000000001 76.90157 23.78425000000001 80.13476 21.90319000000001 83.25561 C 20.022130000000008 86.37645 5.415140000000008 87.89593 -0.000019999999992137418 87.89593 C -5.415179999999992 87.89593 -16.73476999999999 86.94077 -21.021299999999993 83.25561 C -25.307829999999992 79.57045000000001 -21.47160999999999 75.39602000000001 -27.602149999999995 72.65044 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 250.8782035898 291.1053646466)"
        id="j2NLk2C5ZxfaNOeVx_xW0"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -45.19231 6.45046 C -36.85898 -0.17347 3.311959999999999 -18.33586 45.19231 5.382079999999999"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1.0652547702 0 0 1.0652547702 227.3038142564 315.9686140083)"
        id="ljaCRMxZ-I_sX6neq0PEn"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 0 -8.76068 C 4.8359 -8.76068 8.76068 -4.835890000000001 8.76068 0 C 8.76068 4.8359 4.835890000000001 8.76068 0 8.76068 C -4.8359 8.76068 -8.76068 4.835890000000001 -8.76068 0 C -8.76068 -4.8359 -4.835890000000001 -8.76068 0 -8.76068 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1.0652547702 0 0 1.0652547702 273.8422757949 315.9686152435)"
        id="xkgHZ2HjpI4MqsO3v-A1f"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 0 -8.76068 C 4.8359 -8.76068 8.76068 -4.835890000000001 8.76068 0 C 8.76068 4.8359 4.835890000000001 8.76068 0 8.76068 C -4.8359 8.76068 -8.76068 4.835890000000001 -8.76068 0 C -8.76068 -4.8359 -4.835890000000001 -8.76068 0 -8.76068 z"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 250.1303403419 341.6797554784)"
        id="ZxsOq4rlmZKDL49cG12EM"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M -25 3.52564 C -13.67521 -3.3119699999999996 -5.267620000000001 -3.52564 0.5016199999999991 -3.52564 C 6.270849999999999 -3.52564 16.453 -2.67094 25 2.0299199999999997"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 166.0128189744 215.1259193116)"
        id="4h4uE4WlloTr_5HfMrXR5"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 39.17308 -14.15129 C 31.05342 -18.21112 23.22009 -24.621380000000002 11.895299999999999 -24.621380000000002 C 0.5705099999999987 -24.621380000000002 -25.07051 -7.313690000000001 -39.17308 -5.390610000000002 C -33.40385 8.070929999999997 -3.4167399999999972 28.797419999999995 20.942239999999998 23.882889999999996"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-1 0 0 1 333.9871779487 215.1259209685)"
        id="5XIIHyHDIUgcYj_Jr5oZo"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0.0000041485)"
          d="M 39.17308 -14.15129 C 31.05342 -18.21112 23.22009 -24.621380000000002 11.895299999999999 -24.621380000000002 C 0.5705099999999987 -24.621380000000002 -25.07051 -7.313690000000001 -39.17308 -5.390610000000002 C -33.40385 8.070929999999997 -3.4167399999999972 28.797419999999995 20.942239999999998 23.882889999999996"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(1 0 0 1 213.5184103274 171.3806123605)"
        id="f4XeWOM8rxOIxqr4Z95gW"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(0, 0)"
          d="M 14.81706 12.07265 C 8.4068 7.585469999999999 -3.9863599999999995 -16.773500000000002 -4.41371 -29.807689999999997 C -13.60174 -14.209399999999997 -20.65303 -4.807689999999997 -7.83251 29.807690000000004"
          strokeLinecap="round"
        />
      </g>
      <g
        transform="matrix(-1 0 0 1 287.6209744299 171.3806146682)"
        id="mj9mUCadh4uK5q8MJ2hCk"
      >
        <path
          stroke={color}
          strokeWidth="1"
          strokeDashoffset="0"
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          fill="#FFFFFF"
          fillOpacity="0"
          fillRule="nonzero"
          opacity="1"
          vectorEffect="non-scaling-stroke"
          transform=" translate(-0.0000015485, 0)"
          d="M 14.81706 12.07265 C 8.4068 7.585469999999999 -3.9863599999999995 -16.773500000000002 -4.41371 -29.807689999999997 C -13.60174 -14.209399999999997 -20.65303 -4.807689999999997 -7.83251 29.807690000000004"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}
