import { Farm } from '../../domains/Farm';
import ApiHelper from '../../helpers/ApiHelper';
import { BASE_URLS, URLS } from '../apiConstants';

const CarService = {
  getAll: () =>
    ApiHelper.callApi<Array<Farm>>({
      baseUrl: BASE_URLS.WEB_API,
      headers: {},
      method: 'GET',
      url: URLS.GET_FARMS,
    }),
};

export default CarService;
