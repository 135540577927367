import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ManagableUser } from '../../../../domains/ManagableUser';
import { Role } from '../../../../domains/Role';
import {  useEffect, useState } from 'react';
import useSnackbarStore from '../../../../store/Notification/NotificationStore';
import { useTranslation } from 'react-i18next';
import { changeUserRoles } from '../../../../store/ManagableUsersStore/ManagableUserActions';
import { useManagableRolesStore } from '../../../../store/ManagableRoleStore/managableRoleStore';
import { useLoadManagableRoles } from '../../../../store/ManagableRoleStore/ManagableRoleActions';

interface ChangeUserRoleDropdownProps {
  managableUser: ManagableUser;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function ChangeUserRoleDropdown({
  managableUser,
}: ChangeUserRoleDropdownProps) {

  useLoadManagableRoles();
  
  const { managableRolesList } = useManagableRolesStore();
  const rolesList: Array<Role> = managableRolesList;  
  
  const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([]);

  const snackbarStore = useSnackbarStore();

  const { t: translate } = useTranslation();

  const handleShowNotification = (severity: string, message: string) => {
    snackbarStore.openSnackbar(message, severity);
  };

  useEffect(() => {
    const fetchSelectedRoleIds = () => {
      const selectedIds = managableUser.userRoles.map(x => x.id);
     
      setSelectedRoleIds(selectedIds);
    };  
    fetchSelectedRoleIds();
  },[]);

  const handleChange = async (event: SelectChangeEvent<typeof selectedRoleIds>) => {
    try {   
      const {
        target: { value },
      } = event;
      
      if (value.length == 0) {        
        return;
      }    

      await changeUserRoles({
        userId: managableUser.id,
        roles: [value] as string[],
      });

      
      setSelectedRoleIds(typeof value === 'string' ? value.split(',') : value);

    } catch (error) {
      handleShowNotification('alert',  translate('user_mgmt_role_meesage_error') + error);
    }
  };

  return (
    <Select
      multiple
      value={selectedRoleIds}
      onChange={handleChange}
      input={<OutlinedInput />}
      MenuProps={MenuProps}
      style={{ width: '100%' }}
      renderValue={(selected) =>  selected.map(id => rolesList.find(role => role.id === id)?.name).join(', ')}
      key={managableUser.id}
    >
      {rolesList.map((role) => (
           <MenuItem key={role.id} value={role.id}>
             <Checkbox checked={selectedRoleIds.indexOf(role.id) > -1} />
             <ListItemText primary={role.name} />
           </MenuItem>
          ))}
    </Select>
  );
}