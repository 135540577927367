import {  Box, Button, Link, TextField } from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Navigate, useNavigate } from 'react-router-dom';
import AccountBanner from '../../../components/AccountBanner';
import { DEFAULT_LOGGED_ROUTE, PublicRoutes } from '../../../domains/AppRoutes';
import { isAxiosError } from '../../../helpers/ApiHelper';
import { getIsUserAuthenticated } from '../../../helpers/UserHelpers';
import {
  loginAction,
  loginWithGoogleAction,
} from '../../../store/UserStore/UserAuthenticationActions';
import './Login.scss';
import useSnackbarStore from '../../../store/Notification/NotificationStore';

interface LoginFormValues {
  email: string;
  password: string;
}

function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loginError, setLoginError] = useState<string | null>(null);
  const isUserAuthenticated = getIsUserAuthenticated();

  const snackbarStore = useSnackbarStore();

  const handleShowNotification = (severity: string, message: string) => {
    snackbarStore.openSnackbar(message, severity);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>();

  function handleErrorOnLogin(error: unknown) {
    if (isAxiosError(error) && error.data.message) {
      setLoginError(error.data.message);
    } else {
      setLoginError('Erro ao se autenticar.');
    }
  }

  async function onSubmit(data: LoginFormValues) {
    try {
      await loginAction(data);
      navigate(DEFAULT_LOGGED_ROUTE);
    } catch (error) {
      handleErrorOnLogin(error);
    }
  }

  async function loginWithGoogle(data: CredentialResponse) {
    try {
      await loginWithGoogleAction(data);
      navigate(DEFAULT_LOGGED_ROUTE);
    } catch (error) {
      handleErrorOnLogin(error);
    }
  }

  if (isUserAuthenticated) {
    return <Navigate to={DEFAULT_LOGGED_ROUTE} />;
  }

  useEffect(() =>  { 
    loginError && handleShowNotification('error',  loginError); 
  }, [loginError]);

  return (
    <div className="ilpf-login-screen">
      <Box className="ilpf-login-content">
        <Box className="ilpf-login-main-box">
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/rede-ilpf-logo.svg`}
            alt="ILPF Logo"
            title="ILPF Logo"
          />
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/ilpf-digital-logo.png`}
            alt="ILPF Digital Logo"
            title="ILPF Digital Logo"
          />
          <Box className="ilpf-login-create-account-box">
            <span>{t('login_create_account')}</span>
            <Link onClick={() => navigate(PublicRoutes.CREATE_ACCOUNT)}>
              {t('login_create_account_link')}
            </Link>
          </Box>
        </Box>
        <Box className="ilpf-login-form-box">
          <h1>
            <span>{t('login_title')}</span> <span>Rede ILPF</span>
          </h1>
          <span className="ilpf-login-subtitle"> {t('login_subtitle')} </span>
          <Box className="ilpf-login-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="email">{t('login_email')}</label>
              <TextField
                id="email"
                {...register('email', {
                  required: true,
                  pattern: /\S+@\S+\.\S+/,
                })}
                error={!!errors.email}
                variant="outlined"
              />
              {errors.email && errors.email.type === 'required' && (
                <span className="error-message">
                  {t('error_required_email')}
                </span>
              )}
              {errors.email && errors.email.type === 'pattern' && (
                <span className="error-message">
                  {t('error_invalid_email')}
                </span>
              )}
              <label htmlFor="password" className="password-field">
                {t('login_password')}
              </label>
              <TextField
                id="password"
                {...register('password', { required: true })}
                error={!!errors.password}
                type="password"
                variant="outlined"
              />
              {errors.password && (
                <span className="error-message">
                  {t('error_required_password')}
                </span>
              )}
              <Box className="additional-links-box">
                <Link onClick={() => navigate(PublicRoutes.FORGOT_PASSWORD)}>
                  {t('login_forgot_password')}
                </Link>

                <Link onClick={() => navigate(PublicRoutes.TERMS)}>
                  {t('access_privacy_terms')}
                </Link>
              </Box>
              <Button
                type="submit"
                className="login-button"
                variant="contained"
              >
                {t('login_link')}
              </Button>
              <GoogleLogin
                width="322"
                size="large"
                onSuccess={(credentialResponse) => {
                  loginWithGoogle(credentialResponse);
                }}
                onError={() => {
                  setLoginError('Erro ao se autenticar.');
                }}
              />
                {/* navigate(PublicRoutes.MAP_PUBLIC)*/}
              <Box className="ilpf-login-return-to-public-map-box">
                <Link onClick={() => null}>
                  {t('login_return_public_map')}
                </Link>
            </Box>
            </form>
          </Box>
        </Box>
        <section className="about-section">
          <h4>
            Sobre o ILPF Digital{' '}
            <MdOutlineKeyboardArrowDown color="#019136" size={19} />
          </h4>
          <p>{t('about_ilpf_p1')}</p>
          <p>{t('about_ilpf_p2')}</p>
        </section>
      </Box>
      <AccountBanner />
    </div>
  );
}

export default Login;
