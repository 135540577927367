import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../domains/AppRoutes';
import './LoginNavigation.scss';

function LoginNavigation() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box className="ilpf-login-navigation">
      <a onClick={() => navigate(PublicRoutes.LOGIN)} className="login-button">
        <img
          src={`${process.env.PUBLIC_URL}/resources/images/arrow-back.svg`}
          alt="Back"
          title="Back"
        />
        <span>{t('create_account_back_login')}</span>
      </a>
    </Box>
  );
}

export default LoginNavigation;
