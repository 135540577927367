import { useEffect } from 'react';
import { useManagableUserStore } from './managableUserStore';
import { ManageUsersService } from '../../services/ManageUsersService';

interface LoadManagableUserParams {
  page: number;
  itemsPerPage: number;
  searchTerms?: string;
}

export async function changeItemsPerPage(itemsPerPage: number) {
  const { searchTerms } = useManagableUserStore.getState();

  await loadManagableUsers({
    page: 0,
    itemsPerPage,
    searchTerms: searchTerms,
  });
}

export async function changeSearchTerms(searchTerms: string | undefined) {
  const { itemsPerPage } = useManagableUserStore.getState();

  await loadManagableUsers({
    page: 0,
    itemsPerPage,
    searchTerms,
  });
}

export async function changeCurrentPage(page: number) {
  const { itemsPerPage, searchTerms } = useManagableUserStore.getState();

  await loadManagableUsers({
    page,
    itemsPerPage,
    searchTerms,
  });
}

export async function deleteUser(userId: string) {
  const { itemsPerPage, searchTerms, currentPage } = useManagableUserStore.getState();
  
  await ManageUsersService.deleteUser({ userId });

  loadManagableUsers({
    page: currentPage,
    itemsPerPage,
    searchTerms,
  });
}

export async function changeUserRoles({
  userId,
  roles
}: {
  userId: string;
  roles: Array<string>;
}) {
  const { itemsPerPage, searchTerms, currentPage } =
    useManagableUserStore.getState();

  await ManageUsersService.updateUserRoles({ userId, userData: { roles } });

  loadManagableUsers({
    page: currentPage,
    itemsPerPage,
    searchTerms,
  });
}

export function useLoadManagableUsers() {
  const { itemsPerPage, searchTerms, currentPage } = useManagableUserStore();
  
  useEffect(() => {
    loadManagableUsers({
      page: currentPage,
      itemsPerPage,
      searchTerms,      
    });
  }, []);
}

export async function loadManagableUsers({
  page,
  itemsPerPage,
  searchTerms,
}: LoadManagableUserParams) {
  const {
    loadManagableUsersData,
    setManagableUsersIsError,
    setManagableUsersIsLoading,
  } = useManagableUserStore.getState();

  try {
    setManagableUsersIsLoading();

    const { items, totalOfItems } = await ManageUsersService.getUserList({
      page: page.toString(),
      itemsPerPage: itemsPerPage.toString(),
      searchTerms,
    });
    
    loadManagableUsersData({
      managableUsersList: items,
      currentPage: page,
      itemsPerPage,
      totalOfItems,
      searchTerms
    });   

  } catch (error) {
    setManagableUsersIsError();
  }
}
