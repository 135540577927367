import { useTranslation } from 'react-i18next';
import { ChangeUserRoleDropdown } from './ChangeUserRoleDropdown';
import { DeleteUserButton } from './DeleteUserButton';
import './ManageUsersTable.scss';
import Pagination from '../../../../components/Paginator';
import { ManagableUser } from '../../../../domains/ManagableUser';
import { useManagableUserStore } from '../../../../store/ManagableUsersStore/managableUserStore';
import { loadManagableUsers } from '../../../../store/ManagableUsersStore/ManagableUserActions';

export function ManageUsersTable() {
  const { managableUsersList, currentPage, itemsPerPage, totalOfItems } = useManagableUserStore();
  const { t: translate } = useTranslation();

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {    
    loadManagableUsers({page: newPage, itemsPerPage});
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    loadManagableUsers({itemsPerPage: parseInt(event.target.value), page: currentPage});
  };

  return (
    <div className="user-mgmt-table-container">
      <table className="user-mgmt-table">
        <thead>
          <tr>
            <th>{translate('user_mgmt_table_name')}</th>
            <th>{translate('user_mgmt_table_mail')}</th>
            {/* <th>{translate('user_mgmt_table_cpfCnpj')}</th> */}
            <th>{translate('user_mgmt_table_phone')}</th>
            <th>{translate('user_mgmt_table_profile')}</th>
          </tr>
        </thead>
        <tbody>
        {(managableUsersList
          ).map((managableUser: ManagableUser) => (
              <tr key={managableUser.id}>
                <td>{managableUser.name}</td>
                <td>{managableUser.email}</td>
                {/* <td>{managableUser.cpfCnpj}</td> */}
                <td>{managableUser.telephone}</td>
                <td>
                  {<ChangeUserRoleDropdown managableUser={managableUser} key={managableUser.id} />}
                </td>
                <td>
                  <DeleteUserButton userId={managableUser.id} key={managableUser.id}/>
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot  className='pagination'>
              <Pagination 
                list={managableUsersList} 
                colspan={6} 
                count={totalOfItems} 
                rowsPerPage={itemsPerPage}
                page={currentPage} 
                handleChangePage={handleChangePage} 
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50, { label: translate('user_mgmt_pagination_all'), value: -1 }]}
              />          
        </tfoot>        
      </table>
    </div>
  );
}