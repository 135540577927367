import { Navigate, Outlet } from 'react-router-dom';
import { PublicRoutes } from '../../domains/AppRoutes';
import { getIsUserAuthenticated } from '../../helpers/UserHelpers';

interface ProtectedRouteProps {
  isAllowed?: () => boolean;
  redirectPath?: string;
  children: React.ReactElement;
}

function ProtectedRoute({
  isAllowed = getIsUserAuthenticated,
  redirectPath = PublicRoutes.LOGIN,
  children,
}: ProtectedRouteProps) {
  if (!isAllowed()) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
}

export default ProtectedRoute;
