import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { logout } from '../../../../store/UserStore/UserAuthenticationActions';
import { useUserStore } from '../../../../store/UserStore/userStore';
import './UserMenu.scss';

export function UserMenu() {
  const { userName } = useUserStore();
  const firstName = userName?.split(' ')[0] ?? '';
  const { t: translate } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className="avatar"
        id="user-button"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Avatar
          className="pic"
          name={userName || undefined}
          round
          size="42"
          color="#DFEADC"
        />
        <span>{firstName}</span>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-button',
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={logout}>{translate('logout')}</MenuItem>
      </Menu>
    </>
  );
}
