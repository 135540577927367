import { CredentialResponse } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { PublicRoutes } from '../../domains/AppRoutes';
import {
  removeStoredUserData,
  setStoredUserData,
} from '../../helpers/UserHelpers';
import AccountService from '../../services/AccountService';
import { useUserStore } from './userStore';

export async function loginAction(data: { email: string; password: string }) {
  const { setUserIsLoading, loadUserData, setUserIsError } =
    useUserStore.getState();

  setUserIsLoading();

  try {
    const response = await AccountService.login({
      email: data.email,
      password: data.password,
    });

    const userData = {
      accessToken: response.accessToken,
      userId: response.userId,
      userName: response.name,
      userRoles: response.userRoles,
    };

    loadUserData(userData);
    setStoredUserData(userData);
  } catch (error) {
    setUserIsError();

    throw error;
  }
}

export async function loginWithGoogleAction(data: CredentialResponse) {
  const { setUserIsLoading, loadUserData, setUserIsError } =
    useUserStore.getState();

  setUserIsLoading();

  if (!data.credential) {
    setUserIsError();
    return;
  }

  const user = jwtDecode(data.credential) as {
    email: string;
    password: string;
  };

  try {
    const response = await AccountService.loginGoogle({
      email: user.email,
      password: user.password,
    });

    const userData = {
      accessToken: response.accessToken,
      userId: response.userId,
      userName: '',
      userRoles: [],
    };

    loadUserData(userData);
    setStoredUserData(userData);
  } catch (error) {
    setUserIsError();

    throw error;
  }
}

export function logout() {
  const { reset: resetUserStore } = useUserStore.getState();

  removeStoredUserData();

  resetUserStore();

  window.location.pathname = PublicRoutes.LOGIN;
}
