import { useState } from 'react';
import CodeView from './Components/CodeView';
import EmailView from './Components/EmailView';
import NewPassView from './Components/NewPassView';
import './ForgotPassword.scss';
import { CurrentComponent } from './ForgotPassword.types';

function ForgotPassword() {
  const [currentComponent, SetCurrentComponent] = useState<CurrentComponent>(
    CurrentComponent.EMAIL,
  );
  const [email, SetEmail] = useState<string>('EMAIL');
  const [resetToken, SetResetToken] = useState<string>('ResetToken');

  function handleViews(
    component: CurrentComponent,
    currentEmail: string,
    token?: string,
  ) {
    SetEmail(currentEmail);
    SetResetToken(token || 'ResetToken');
    SetCurrentComponent(component);
  }
  if (currentComponent === CurrentComponent.EMAIL)
    return <EmailView nextComponent={handleViews} />;

  if (currentComponent === CurrentComponent.CODE_VIEW)
    return <CodeView nextComponent={handleViews} email={email} />;

  return (
    <NewPassView
      nextComponent={handleViews}
      email={email}
      resetToken={resetToken}
    />
  );
}

export default ForgotPassword;
