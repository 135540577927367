import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import useSnackbarStore from '../../../../store/Notification/NotificationStore';
import { deleteUser } from '../../../../store/ManagableUsersStore/ManagableUserActions';

interface DeleteUserButtonProps {
  userId: string;
}

export function DeleteUserButton({ userId }: DeleteUserButtonProps) {
  const { t: translate } = useTranslation();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const handleCloseDialog = () => setShowConfirmationDialog(false);
  const handleOpenDialog = () => setShowConfirmationDialog(true);

  const snackbarStore = useSnackbarStore();

  const handleShowNotification = (severity: string, message: string) => {
    snackbarStore.openSnackbar(message, severity);
  };

  async function handleDeleteUser(this: unknown) {
    try {
      await deleteUser(userId);
      handleCloseDialog();
      handleShowNotification('success',  translate('user_mgmt_delete_message_success'));
    } catch (error) {
      handleShowNotification('error',  translate('user_mgmt_delete_message_error'));
    }
  }

  return (
    <>
      <button
        aria-label={translate('user_mgmt_table_delete')}
        className="delete-user-button"
        onClick={handleOpenDialog}
      >
        <MdDelete size={25} />
      </button>
      <Dialog
        open={showConfirmationDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate('user_mgmt_delete_user_modal_title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate('user_mgmt_delete_user_modal_content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            {translate('user_mgmt_delete_user_modal_cancel')}
          </Button>
          <Button onClick={handleDeleteUser} autoFocus>
            {translate('user_mgmt_delete_user_modal_confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

